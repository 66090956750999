export const errorsFunc = (val, time) => {
    switch (val) {
        case 1:
            return `Aktywacja EMS nie jest możliwa. Brak komunikacji z instalacją. Instalacja ostatni raz online widziana: ${time}`

        case 2:
            return `Aktywacja EMS nie powiodła się. Brak komunikacji z instalacją. Instalacja ostatni raz widziana: ${time}`
    
        default:
            return `Brak komunikacji z instalacją. Instalacja ostatni raz widziana: ${time}`
    }
}