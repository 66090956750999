import React, { useEffect, useState } from 'react';
import { mainStyleClasses } from '../../../../theme'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { Grid, TextField, Box, Typography, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, IconButton, Divider, Drawer } from '@material-ui/core'
import { filtersandsort } from '../../../../functions'
import { fetchDevicesTypes } from '../../../../data'
import { deviceFriendlyName3 } from '../../../../dictionary/deviceFriendlyameDictionary'

const FilterDrawer = ({ open, handleDrawerClose, handleResetFilters, drawerWidth,
    deviceSnValue, deviceKeyValue, customerNameValue, locationValue, typeValue, onlineValue, onlineFromValue, onlineToValue,
    registrationValue, telemetryValue, telemetryFromValue, telemetryToValue, partnerValue, filterDevice, setFilterDevice, setPage, accessScada }) => {

    const classes = mainStyleClasses({drawerWidth: drawerWidth});
    const [devicesTypes, setDevicesTypes] = useState(null)

useEffect(() => {
  let mounted = true

  fetchDevicesTypes().then(res => {
    if(res.status === "ok") {
        // let typeData = res.data
        // let typeDataArr

        // typeData.map((item, index) => {

        // })
        
        setDevicesTypes(res.data)
    }
})

  return () => {
    mounted = false
  }
}, [])


    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Grid container direction="row" alignItems='center' >
                    <Grid item xs={9}>
                        <Button variant="contained" color="primary" onClick={handleResetFilters}>
                            Zresetuj filtry
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container direction="row" >
                    <Grid item xs={12}>
                        <br />
                        <Typography variant="h5">
                            <Box fontWeight="fontWeightBold">
                                Filtry
                            </Box>
                        </Typography>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Typ urządzenia</FormLabel>
                            <RadioGroup aria-label="type-filter" name="deviceType" value={typeValue} onChange={(e) => filtersandsort.filterInstallationList(e, "type", setFilterDevice, filterDevice, setPage)}>
                                {/*<FormControlLabel value="ATMO" control={<Radio className={classes.radio} />} label="ATMO" />
                                <FormControlLabel value="GEO" control={<Radio className={classes.radio} />} label="GEO" />
                                <FormControlLabel value="ALLINONE" control={<Radio className={classes.radio} />} label="ALLINONE" />*/}
                                {devicesTypes? 
                                    deviceFriendlyName3(devicesTypes).map((item, index) => <FormControlLabel key={item} value={item.toUpperCase()} control={<Radio className={classes.radio} />} label={item} />)
                                    : null }
                                    <FormControlLabel value="NIEZNANY" control={<Radio className={classes.radio} />} label="Nieznany" />
                                    <FormControlLabel value="WSZYSTKIE" control={<Radio className={classes.radio} />} label="Wszystkie" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Nr seryjny" name="deviceSn" type="text" onChange={(e) => filtersandsort.filterInstallationList(e, "SN", setFilterDevice, filterDevice, setPage)}
                            value={deviceSnValue} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Klucz" name="deviceKey" type="text" onChange={(e) => filtersandsort.filterInstallationList(e, "key", setFilterDevice, filterDevice, setPage)}
                            value={deviceKeyValue} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Aktywność online</FormLabel>
                            <RadioGroup aria-label="online-filter" name="telemetryUpdate" value={onlineValue} onChange={(e) => filtersandsort.filterInstallationList(e, "online", setFilterDevice, filterDevice, setPage)}>
                                <FormControlLabel value="ONLINE" control={<Radio className={classes.radio} />} label="Online" />
                                <FormControlLabel value="OFFLINE" control={<Radio className={classes.radio} />} label="Dawniejsza aktywność" />
                                <FormControlLabel value="NIGDY" control={<Radio className={classes.radio} />} label="Nigdy nie widziana online" />
                                <FormControlLabel value="ZAKRES" control={<Radio className={classes.radio} />} label="Zakres czasowy ostatniej aktywności" />
                                { onlineValue === "ZAKRES" ?
                                    <div>
                                        <form noValidate>
                                            <TextField
                                                id="online-od"
                                                name="online-od"
                                                label="Od"
                                                type="datetime-local"
                                                value={onlineFromValue}
                                                onChange={(e) => filtersandsort.filterOnlinePeriod(e, setFilterDevice, filterDevice)}
                                            />
                                            <TextField
                                                id="online-do"
                                                name="online-do"
                                                label="Do"
                                                type="datetime-local"
                                                value={onlineToValue}
                                                onChange={(e) => filtersandsort.filterOnlinePeriod(e, setFilterDevice, filterDevice)}
                                            />
                                        </form>
                                    </div> : null
                                }
                                <FormControlLabel value="WSZYSTKIE" control={<Radio className={classes.radio} />} label="Wszystkie" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Start telemetrii</FormLabel>
                            <RadioGroup aria-label="telemetry-filter" name="telemetryStart" value={telemetryValue} onChange={(e) => filtersandsort.filterInstallationList(e, "start", setFilterDevice, filterDevice, setPage)}>
                                <FormControlLabel value="ZAKRES" control={<Radio className={classes.radio} />} label="Zakres czasowy startu telemetrii" />
                                { telemetryValue === "ZAKRES" ?
                                    <div>
                                        <form noValidate>
                                            <TextField
                                                id="telemetry-od"
                                                name="telemetry-od"
                                                label="Od"
                                                type="datetime-local"
                                                value={telemetryFromValue}
                                                onChange={(e) => filtersandsort.filterTelemetryPeriod(e, setFilterDevice, filterDevice)}
                                            />
                                            <TextField
                                                id="telemetry-do"
                                                name="telemetry-do"
                                                label="Do"
                                                type="datetime-local"
                                                value={telemetryToValue}
                                                onChange={(e) => filtersandsort.filterTelemetryPeriod(e, setFilterDevice, filterDevice)}
                                            />
                                        </form>
                                    </div> : null
                                }
                                <FormControlLabel value="WSZYSTKIE" control={<Radio className={classes.radio} />} label="Wszystkie" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Klient" name="customerName" type="text" onChange={(e) => filtersandsort.filterInstallationList(e, "customer", setFilterDevice, filterDevice, setPage)}
                            value={customerNameValue} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Lokalizacja" name="location" type="text" onChange={(e) => filtersandsort.filterInstallationList(e, "location", setFilterDevice, filterDevice, setPage)}
                            value={locationValue} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Rejestracja urządzenia</FormLabel>
                            <RadioGroup aria-label="registration-filter" name="deviceRegistration" value={registrationValue} onChange={(e) => filtersandsort.filterInstallationList(e, "registration", setFilterDevice, filterDevice, setPage)}>
                                <FormControlLabel value="ZAREJESTROWANE" control={<Radio className={classes.radio} />} label="Zarejestrowane" />
                                <FormControlLabel value="NIEZAREJESTROWANE" control={<Radio className={classes.radio} />} label="Niezarejestrowane" />
                                <FormControlLabel value="WSZYSTKIE" control={<Radio className={classes.radio} />} label="Wszystkie" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {accessScada === 1?
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Partner</FormLabel>
                            <RadioGroup aria-label="partner-filter" name="partner" value={partnerValue} onChange={(e) => filtersandsort.filterInstallationList(e, "partner", setFilterDevice, filterDevice, setPage)}>
                                <FormControlLabel value="EUROS" control={<Radio className={classes.radio} />} label="Euros Energy" />
                                <FormControlLabel value="EON" control={<Radio className={classes.radio} />} label="E.ON" />
                                <FormControlLabel value="POLENERGIA" control={<Radio className={classes.radio} />} label="POLENERGIA" />
                                <FormControlLabel value="RYNEK WTÓRNY" control={<Radio className={classes.radio} />} label="RYNEK WTÓRNY" />
                                <FormControlLabel value="WSZYSTKIE" control={<Radio className={classes.radio} />} label="Wszystkie" />
                            </RadioGroup>
                        </FormControl>
                    </Grid> : null}
                </Grid>
            </Drawer>
        </div>
    );
}

export default FilterDrawer