import React, { useState, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField, Select, Checkbox } from 'final-form-material-ui'
import {
    Button,
    MenuItem,
    Grid,
    Typography,
    Dialog,
    DialogContent, IconButton,
    FormControlLabel, Snackbar
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../theme'
import { default as serviceNote } from '../../dictionary/serviceNoteDictionary'
import fetchAddServiceNote from '../../data/fetchAddServiceNote'
import fetchGetParts from '../../data/fetchGetParts'
import fetchSendEmail from '../../data/fetchSendEmail'
import { TypesParts } from '../'
import { fetchGetServicers, fetchPush, sendSmsPush, sendEmail, templateMsg } from '../../data'
import { dateConvert } from '../../functions'
import plLocale from "date-fns/locale/pl";

const Alert = (props) => {
    return <MuiAlert elevation={2} variant="filled" {...props} />;
}

const dateFormat = () => {
    const actualDate = new Date()

    return actualDate;
}


const AddServiceNote = ({ token, deviceId, customerId, customer, handleClose, note, action, changeServiceNotesList, perm, user, deviceSerial, keyM, partner_id, setNewNoteAdd, accessScada, partner }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState(false)
    const [endingInfo, setEndingInfo] = useState({ status: false, state: false })
    const actualDate = dateFormat()
    const [partsList, setPartsList] = useState(null)
    const [parts, setParts] = useState([])
    const [send, setSend] = useState(false)
    const [noteContent, setNoteContent] = useState()
    const [error, setError] = useState("")
    const [servicers, setServicers] = useState(null)
    const [selectedDate, handleDateChange] = useState(note && note.date ? dateConvert(note.date) : actualDate)
    const [serviceStatusMess, setServiceStatusMess] = useState({ statusMsg: "", textMsg: "" })
    const [newDeviceStatus, setDeviceStatus] = useState({ status: false, phone: { status: false, message: "" }, appPush: { status: false, message: "" }, email: { status: false, message: "" }, nullMessage: { status: false, message: "" }, 
    endingInfoSend: {status: false, message: "", state: false} })
    const [partnerInfo, setPartnerInfo] = useState(null)

    const handleChangeMessage = (event) => {
        setServiceStatusMess({ ...serviceStatusMess, statusMsg: event.target.value })
    }

    const handleClickEndingInfoOpen = () => {
        setEndingInfo({ ...endingInfo, status: true});
    }
    const handleClickEndingInfoClose = () => {
        setEndingInfo({ ...endingInfo, status: false});
    }
    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleCloseType = () => {
        setOpen(false);

    }

    const handleSetParts = ({ part }) => {
        let helpArr = parts.slice()
        let newPartsArr = []

        if (helpArr.find(item => item.name === part.name)) {
            newPartsArr = helpArr.filter(item => item.name !== part.name)
            setParts(newPartsArr)
        } else {
            helpArr.push(part)
            setParts(helpArr)
        }
    }


    const handleCloseSnackbarPhone = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeviceStatus({ ...newDeviceStatus, phone: { ...newDeviceStatus.phone, status: false } })
    }
    const handleCloseSnackbarPush = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeviceStatus({ ...newDeviceStatus, appPush: { ...newDeviceStatus.appPush, status: false } })
    }
    const handleCloseSnackbarEmail = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeviceStatus({ ...newDeviceStatus, email: { ...newDeviceStatus.email, status: false } })
    }

    const handleCloseSnackbarNullMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeviceStatus({ ...newDeviceStatus, nullMessage: { ...newDeviceStatus.nullMessage, status: false } })
    }

    const handleCloseSnackbarEndingInfo = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeviceStatus({ ...newDeviceStatus, endingInfoSend: { ...newDeviceStatus.endingInfoSend, status: false } })
    }



    const templateEmail = (val, newStat) => {
        switch (val) {
            case 1:
                return {
                    sub: 'Przekazanie zgłoszenia',
                    msg: `<b>Zgłoszenie serwisowe:</b> ${note.id}<br/>
                    <b>Rozpoczęte:</b> ${dateConvert(note.date).toLocaleString()}<br/>
                    <b>Numer seryjny:</b> ${deviceSerial}<br/>
                    <b>Klucz:</b> ${keyM}<br /><br /> 
                    Zgłoszenie zostało przekazane. <br /><br /> 
                    Więcej informacji można uzyskać w Panelu Euros Energy.`
                }

            case 2:
                return {
                    sub: 'Zmiana statusu',
                    msg: `<b>Zgłoszenie serwisowe:</b> ${note.id}<br/>
                    <b>Rozpoczęte:</b> ${dateConvert(note.date).toLocaleString()}<br/>
                    <b>Numer seryjny:</b> ${deviceSerial}<br/>
                    <b>Klucz:</b> ${keyM}<br /><br /> 
                    Zmiana statusu z ${serviceNote.serviceNoteStatus[note.note_status_id]} na ${newStat}. <br /><br /> 
                    Więcej informacji można uzyskać w Panelu Euros Energy.`
                }

            case 3:
                return {
                    sub: "Zmiana kategorii",
                    msg: `<b>Zgłoszenie serwisowe:</b> ${note.id}<br/>
                    <b>Rozpoczęte:</b> ${dateConvert(note.date).toLocaleString()}<br/>
                    <b>Numer seryjny:</b> ${deviceSerial}<br/>
                    <b>Klucz:</b> ${keyM}<br /><br /> 
                    Zmiana kategorii z ${serviceNote.serviceNoteType[note.note_type_id]} na ${newStat}. <br /><br /> 
                    Więcej informacji można uzyskać w Panelu Euros Energy.`
                }

            case 4:
                return {
                    sub: 'Nowe zgłoszenie',
                    msg: `<b>Zgłoszenie serwisowe:</b> ${newStat.id}<br/>
                    <b>Rozpoczęte:</b> ${dateConvert(newStat.date).toLocaleString()}<br/>
                    <b>Numer seryjny:</b> ${deviceSerial}<br/>
                    <b>Klucz:</b> ${keyM}<br /><br />
                    <b>Temat zgłoszenia:</b> ${newStat.subject}<br /><br /> 
                    <b>Treść:</b> ${JSON.parse(newStat.content)[0].msg}<br /><br /> 
                    Więcej informacji można uzyskać w Panelu Euros Energy.`
                }

            case 5:
                return {
                    sub: 'Przekazano zgłoszenie',
                    msg: `Zostało Ci przekazane poniższe zgłoszenie.<br /><br />
                    <b>Zgłoszenie serwisowe:</b> ${note ? note.id : newStat.id}<br/>
                    <b>Rozpoczęte:</b> ${dateConvert(newStat.date).toLocaleString()}<br/>
                    <b>Numer seryjny:</b> ${deviceSerial}<br/>
                    <b>Klucz:</b> ${keyM}<br /><br />
                    <b>Temat zgłoszenia:</b> ${newStat.subject}<br /><br /> 
                    <b>Treść:</b> ${JSON.parse(newStat.content)[0].msg}<br /><br /> 
                    Więcej informacji można uzyskać w Panelu Euros Energy.`
                }

            default:
                break;
        }
    }

    const saveClientMsg = (statusSend, client, values) => {

        let noteValues
        let mainSubmit = document.getElementById('mainSubmit')
        let { content, ...more } = note
        let serviceMessage = `${statusSend.appPush || statusSend.phone || statusSend.email ? `Wysłano wiadomość do klienta (${statusSend.appPush ?
            'PUSH poprzez aplikację mobilną' : ''}${statusSend.phone ? `, SMS na numer telefonu ${client.phoneClient}` : ''}${statusSend.email ? `, EMAIL na adres ${client.emailClient}` : ''}): <br />${values.serviceStatusMessage ?
                `${values.serviceStatusMessage}` : ''} ${values.msgText ? `<br />${values.msgText}` : ''}`
            : `Próbowano wysłać wiadomość do klienta, ale nie udało się. Proszę sprawdzić czy posiadamy poprawne dane klienta: numer telefonu lub adres email.`}`
        let contentArr = []
        contentArr = JSON.parse(content)

        if(values.endingInfoSend){
            handleClickEndingInfoClose()
            setDeviceStatus({ ...newDeviceStatus, endingInfoSend: { ...newDeviceStatus.endingInfoSend, status: false, state: true } })
            setEndingInfo({ status: false, state: true })
            // mainSubmit.onclick()
        }

        if (serviceMessage) {
            contentArr.push(
                {
                    timestamp: dateConvert(actualDate),
                    msg: serviceMessage,
                    user_email: `${user.sub}`,
                    user_id: `${user.user_id}`,
                    visibility: false,
                    endingInfoSend: values.endingInfoSend? values.endingInfoSend : false,
                    clientInfo: true
                }
            )
            noteValues = { ...more, content: JSON.stringify(contentArr) }

            fetchAddServiceNote(note ? note.id : null, noteValues, "PUT")
                .then(response => {
                    if (response.status === "ok") {
                        changeServiceNotesList(action, response.data)
                        setError("")
                    }
                    return response
                }).then(response => {
                    if(values.endingInfoSend){
                        mainSubmit.click()
                    }
                })

        }
    }


    const onSubmit2 = async values => {
        let serviceMessage
        let phoneClient = ''
        let emailClient = ''
        let msgSnackbar = {}
        let contentArr = []
        let statusSend = { appPush: false, phone: false, email: false }

        let noteValues
        let { content, ...more } = note

        if (note && note.id && (values.serviceStatusMessage !== "" || (values.msgText && values.msgText !== ""))) {

            if (note && note.method_of_notification === 10) {
                phoneClient = noteContent[0].phone
                emailClient = noteContent[0].email

                sendSmsPush({
                    service_note_id: note.id, customer_id: note.customer_id,
                    body: templateMsg("sms-push", { ...note, device_sn: deviceSerial, key: "010101002B74DE5A749F", statusMsg: values.serviceStatusMessage, textMsg: values.msgText })
                },
                "010101002B74DE5A749F", phoneClient, "Euros Energy - Informacje o zgłoszeniu").then(response => {
                        if (response.push === 200) {
                            msgSnackbar = { ...msgSnackbar, appPush: { status: true, message: `Wiadomość PUSH została wysłana do klienta przez aplikację mobilną.`, color: "success" } }
                            statusSend = { ...statusSend, appPush: true }
                            setServiceStatusMess('')
                        } else {
                            msgSnackbar = { ...msgSnackbar, appPush: { status: true, message: `Nie udało się wysłać wiadomości PUSH do klienta przez aplikację mobilną.`, color: "error" } }
                        }
                        if (response.phone === 200) {
                            msgSnackbar = { ...msgSnackbar, phone: { status: true, message: `Wiadomość została wysłana do klienta na numer ${phoneClient}.`, color: "success" } }
                            statusSend = { ...statusSend, phone: true }
                            setServiceStatusMess('')
                        } else {
                            msgSnackbar = { ...msgSnackbar, phone: { status: true, message: `Nie udało się wysłać wiadomości SMS do klienta.`, color: "error" } }
                        }
                    })
                    .then(res => {
                        sendEmail({
                            service_note_id: note.id, customer_id: note.customer_id, subject: "Euros Energy - Informacje o zgłoszeniu",
                            body: templateMsg("emailClient", { ...note, device_sn: deviceSerial, key: "010101002B74DE5A749F", statusMsg: values.serviceStatusMessage, textMsg: values.msgText }),
                            email: emailClient
                        }).then(response => {
                            if (response.status === 200) {
                                msgSnackbar = { ...msgSnackbar, email: { status: true, message: `Wiadomość została wysłana do klienta na adres email ${emailClient}`, color: "success" } }
                                statusSend = { ...statusSend, email: true }
                                setServiceStatusMess('')
                            } else {
                                msgSnackbar = { ...msgSnackbar, email: { status: true, message: `Nie udało się wysłać wiadomości EMAIL do klienta.`, color: "error" } }
                            }
                            setDeviceStatus({ ...newDeviceStatus, phone: msgSnackbar.phone, appPush: msgSnackbar.appPush, email: msgSnackbar.email })
                        })
                    }).then(res => saveClientMsg(statusSend, { phoneClient, emailClient }, values))
            } else {
                if ((note && note.customer && note.customer.phone) || (note && customer && customer.phone)) {
                    phoneClient = note.customer && note.customer.phone ? note.customer.phone : customer.phone
                    sendSmsPush({
                        service_note_id: note.id, customer_id: note.customer_id,
                        body: templateMsg("sms-push", {
                            ...note, device_sn: deviceSerial, key: "010101002B74DE5A749F", statusMsg: values.serviceStatusMessage,
                            textMsg: values.msgText
                        })
                    },
                    "010101002B74DE5A749F", phoneClient, "Euros Energy - Informacje o zgłoszeniu").then(response => {
                            if (response.push === 200) {
                                msgSnackbar = { ...msgSnackbar, appPush: { status: true, message: `Wiadomość PUSH została wysłana do klienta przez aplikację mobilną.`, color: "success" } }
                                statusSend = { ...statusSend, appPush: true }
                                setServiceStatusMess('')
                            } else {
                                msgSnackbar = { ...msgSnackbar, appPush: { status: true, message: `Nie udało się wysłać wiadomości PUSH do klienta.`, color: "error" } }
                            }
                            if (response.phone === 200) {
                                msgSnackbar = { ...msgSnackbar, phone: { status: true, message: `Wiadomość została wysłana do klienta na numer ${phoneClient}.`, color: "success" } }
                                statusSend = { ...statusSend, phone: true }
                                setServiceStatusMess('')
                            } else {
                                msgSnackbar = { ...msgSnackbar, phone: { status: true, message: `Nie udało się wysłać wiadomości SMS do klienta.`, color: "error" } }
                            }

                        }).then(res => {

                            if (note && ((note.customer && note.customer.email) || (customer && customer.email))) {
                                emailClient = note.customer && note.customer.email !== "" ? note.customer.email : customer.email

                                sendEmail({
                                    service_note_id: note.id, customer_id: note.customer_id, subject: "Euros Energy - Informacje o zgłoszeniu",
                                    body: templateMsg("emailClient", { ...note, device_sn: deviceSerial, key: "010101002B74DE5A749F", statusMsg: values.serviceStatusMessage, textMsg: values.msgText }),
                                    email: emailClient
                                }).then(response => {

                                    if (response.status === 200) {
                                        msgSnackbar = { ...msgSnackbar, email: { status: true, message: `Wiadomość została wysłana do klienta na adres email ${emailClient}`, color: "success" } }
                                        statusSend = { ...statusSend, email: true }
                                        setServiceStatusMess('')
                                    } else {
                                        msgSnackbar = { ...msgSnackbar, email: { status: true, message: `Nie udało się wysłać wiadomości EMAIL do klienta.`, color: "error" } }
                                    }

                                    return response
                                }).then(response => setDeviceStatus({ ...newDeviceStatus, phone: msgSnackbar.phone, appPush: msgSnackbar.appPush, email: msgSnackbar.email }))

                            } else {
                                msgSnackbar = { ...msgSnackbar, email: { status: true, message: `Nie udało się wysłać wiadomości EMAIL do klienta, ponieważ nie posiadamy poprawnego adresu.`, color: "error" } }
                                setDeviceStatus({ ...newDeviceStatus, phone: msgSnackbar.phone, appPush: msgSnackbar.appPush, email: msgSnackbar.email })
                            }


                        }).then(response => saveClientMsg(statusSend, { phoneClient, emailClient }, values))

                } else {
                    msgSnackbar = { ...msgSnackbar, phone: { status: true, message: `Nie udało się wysłać wiadomości SMS do klienta, ponieważ nie posiadamy poprawnego numeru.`, color: "error" } }
                }

            }

        } else {
            msgSnackbar = { ...msgSnackbar, nullMessage: { status: true, message: `Nie można wysłać pustej wiadomości do klienta.`, color: "error" } }
            setDeviceStatus({ ...newDeviceStatus, nullMessage: msgSnackbar.nullMessage })
        }
    }

    const onSubmit = async values => {
        let contentArr = []
        let notesContent = ""
        let { content, ...val } = values
        let sendValues
        let dateTimeISO = selectedDate.toISOString()

        values = { device_id: deviceId, customer_id: customerId, ...val }

        if (note && note.id) {
            contentArr = JSON.parse(note.content)
            values = { ...values, id: note.id }
        }
        if (content && content.trim().length !== 0) {
            notesContent += `${content}<br />`
        }

        if (action === "PUT" && parseInt(note.note_type_id) !== parseInt(values.note_type_id)) {
            notesContent += `Zmiana kategorii z ${serviceNote.serviceNoteType[note.note_type_id]} na ${serviceNote.serviceNoteType[values.note_type_id]}<br />`
            if(!note.hidden || note.hidden === false)
            handleSendEmail(templateEmail(3, serviceNote.serviceNoteType[values.note_type_id]))
        }

        if (action === "PUT" && parseInt(note.note_status_id) !== parseInt(values.note_status_id)) {
            if (parseInt(values.note_status_id) === 5 && !endingInfo.state)
                handleClickEndingInfoOpen()

            notesContent += `Zmiana statusu z ${serviceNote.serviceNoteStatus[note.note_status_id]} na ${serviceNote.serviceNoteStatus[values.note_status_id]}<br />`
            if(!note.hidden || note.hidden === false)
            handleSendEmail(templateEmail(2, serviceNote.serviceNoteStatus[values.note_status_id]))
        }

        if (action === "PUT" && (note.parts.length !== parts.length || !note.parts.every((val, index) => val === parts[index]))) {
            notesContent += `Zmiana typu z: ${note.parts.map(part => ` ${part.name}`)} <br />na: ${parts.map(part => ` ${part.name}`)}<br />`
        }

        if (values.sendEmail) {
            notesContent += `Zgłoszenie zostało przekazane do ${accessScada && accessScada.partner_id !== 1 && user.role === 'partner' ? 'Euros Energy' : partner.name}<br />`
            if(!note.hidden || note.hidden === false)
            handleSendEmail(templateEmail(1))
        }

        if (notesContent.trim().length !== 0) {
            contentArr.push(
                {
                    timestamp: dateConvert(actualDate),
                    msg: `${notesContent}`,
                    user_email: `${user.sub}`,
                    user_id: `${user.user_id}`,
                    visibility: values.visibility ? values.visibility : false
                }
            )
        }

        sendValues = {
            subject: val.subject,
            content: JSON.stringify(contentArr),
            customer_id: customerId,
            device_id: deviceId,
            note_status_id: parseInt(values.note_status_id),
            executive_id: values.executive_id ? values.executive_id : note && note.executive_id ? note.executive_id : user.user_id,
            hidden: values.hidden
        }

        if (user.role === 'admin') {
            sendValues = {
                ...sendValues,
                date: dateTimeISO.substring(0, dateTimeISO.length - 5)
            }
        }

        if (values.note_type_id || parseInt(values.note_type_id))
            sendValues = { ...sendValues, note_type_id: parseInt(values.note_type_id) }

        if (values.method_of_notification || parseInt(values.method_of_notification))
            sendValues = { ...sendValues, method_of_notification: parseInt(values.method_of_notification) }

        if (parts.length > 0) {
            let saveParts = parts.map(item => item.id)
            sendValues = { ...sendValues, parts: saveParts }
        }

        if (!endingInfo.state && parseInt(values.note_status_id) === 5 && !endingInfo.status) {
            setDeviceStatus({ ...newDeviceStatus, endingInfoSend: { status: true, message: `Nie można Zakończyć zgłoszenia bez wysłania podsumowania do klienta. Dodaj podsumowanie i dopiero zapisz zmianę statusu.`, color: "error" }})
        } else {
            if (notesContent.trim().length !== 0) {
                fetchAddServiceNote(note ? note.id : null, sendValues, action)
                    .then(response => {
                        if (action === "PUT" && response.status === "ok") {
                            changeServiceNotesList(action, response.data)
                            if (parseInt(values.executive_id) && (note && note.executive_id !== parseInt(values.executive_id)) && user.user_id !== parseInt(values.executive_id) && user.user_id !== note.executive_id) {
                                handleSendEmailExecutive(templateEmail(5, sendValues), parseInt(values.executive_id))
                            }
                            setError("")
                        } else if (action === "POST" && response.status === "ok") {
                            changeServiceNotesList(action, response.data)
                            if(!values.hidden || values.hidden === false)
                            handleSendEmail(templateEmail(4, response.data))
                            if (parseInt(values.executive_id) && parseInt(values.executive_id) !== 0 && user.user_id !== parseInt(values.executive_id)) {
                                handleSendEmailExecutive(templateEmail(5, response.data), parseInt(values.executive_id))
                            }
                            setNewNoteAdd({ status: true, message: `Dodano nową notatkę o numerze id ${response.data.id}` })
                            handleClose()
                        }
                    })
            } else {
                setError("Nie można wysłać pustej notatki.")
            }
        }
    }

    const handleSendEmail = (val) => {
        let payload = {
            subject: `Zgłoszenie serwisowe - ${val.sub}`,
            body: val.msg,
            user_id: accessScada ? (user.role === 'partner' && accessScada.partner_id !== 1 ? 45 : partner_id === 1 ? 45 : (partnerInfo ? partnerInfo[0].user_id : console.log("Brak id specjalnego usera Partnera"))) : console.log("nie udało się")
            //user_id: accessScada ? (user.role === 'partner' && accessScada.partner_id !== 1 ? 38 : partner_id === 1 ? 38 : (partnerInfo ? partnerInfo[0].user_id : console.log("Brak id specjalnego usera Partnera"))) : console.log("nie udało się")
        }
console.log(accessScada ? (user.role === 'partner' && accessScada.partner_id !== 1 ? 45 : partner_id === 1 ? 45 : (partnerInfo ? partnerInfo[0].user_id : console.log("Brak id specjalnego usera Partnera"))) : console.log("nie udało się"))
console.log({payload})
        fetchSendEmail(payload)
            .then(response => response.json())
            .then(response => {
                if (response.status === 'ok')
                    setSend(true)
            })
    }

    const handleSendEmailExecutive = (val, id) => {
        let payload = {
            subject: `Zgłoszenie serwisowe - ${val.sub}`,
            body: val.msg,
            user_id: id
        }

        fetchSendEmail(payload)
            .then(response => response.json())
            .then(response => {
                if (response.status === 'ok')
                    setSend(true)
            })
    }

    const getServicers = () => {
        fetchGetServicers()
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.status)
                }
            }).then(response => {
                setServicers(response.data)
            })
            .catch(error => {
                // if (error.message === "403") {
                //     sessionStorage.removeItem("APIToken")
                // }
            })
    }

    useEffect(() => {
        let mounted = true

        getServicers()

        return function cleanup() {
            mounted = false
        }
    }, [])

    useEffect(() => {
        let mounted = true
        if (partsList === null)
            fetchGetParts().then(response => setPartsList(response.data))

        return () => {
            mounted = false
        }
    }, [])

    useEffect(() => {
        let mounted = true
        if (note && note.parts && note.parts.length > 0)
            setParts(note.parts)

        if (note) {
            let content = JSON.parse(note.content)
            let endingInfo = content.find(el => el.endingInfoSend === true)
            setNoteContent(JSON.parse(note.content))
            if(endingInfo !== undefined && endingInfo !== false)
            setEndingInfo({ status: false, state: true })
        }
        return () => {
            mounted = false
        }
    }, [note])


    useEffect(() => {
        let mounted = true
        if (partner) {
            const specialUserService = partner.special_users.filter(user => user.function === "SERVICE")
       
            setPartnerInfo(specialUserService)
        }

        return () => {
            mounted = false
        }
    }, [partner])
    return (
        <React.Fragment>
            <Typography variant="h2">{note && note.date ? user.role !== "admin" && note && parseInt(note.note_status_id) > 4 ? "Zgłoszenie tylko do odczytu" : "Edycja zgłoszenia" : "Nowe zgłoszenie"}</Typography>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Form
                    initialValues={null}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, form, submitting, values }) => (
                        <form onSubmit={event => {
                            handleSubmit(event).then(form.change('content', null)).then(form.change('visibility', undefined))
                        }}>
                            <Grid
                                container
                                direction="row">
                                <Grid item xs={12}>
                                    {accessScada.partner_id === 1 && !note ?
                                        <FormControlLabel
                                            label="Zgłoszenie wewnętrzne (niewidoczne)"
                                            control={
                                                <Field
                                                    name="hidden"
                                                    component={Checkbox}
                                                    type="checkbox"
                                                    color="primary"
                                                />
                                            }
                                        /> : null}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row">
                                <Grid item xs={6}>
                                    {user.role === "admin" ?
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                                            <KeyboardDateTimePicker
                                                variant="inline"
                                                ampm={false}
                                                label="Data zgłoszenia"
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                format="dd.MM.yyyy, HH:mm"
                                            />
                                        </MuiPickersUtilsProvider>
                                        : <Field
                                            name="date"
                                            component={TextField}
                                            type="text"
                                            label="Data zgłoszenia"
                                            defaultValue={note && note.date ? dateConvert(note.date).toLocaleString() : actualDate.toLocaleString()}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: true,
                                                disabled: true,
                                            }}
                                            fullWidth
                                        />

                                    }

                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="modification_date"
                                        component={TextField}
                                        type="text"
                                        label="Data modyfikacji"
                                        defaultValue={note && note.modification_date ? dateConvert(note.modification_date).toLocaleString() : actualDate.toLocaleString()}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                            disabled: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="subject"
                                    component={TextField}
                                    defaultValue={note && note.subject ? note.subject : null}
                                    type="text"
                                    label="Tytuł/Temat"
                                    fullWidth
                                    required
                                    InputProps={{
                                        readOnly: note ? true : false,
                                        disabled: note ? true : false,
                                    }}

                                />
                            </Grid>
                            <Grid
                                container
                                direction="row">
                                <Grid item xs={accessScada && (user.role === "partner" && accessScada.partner_id !== 1) ? 6 : 4}>
                                    <Field
                                        name="note_type_id"
                                        label="Kategoria"
                                        type="text"
                                        component={Select}
                                        defaultValue={note && note.note_type_id ? note.note_type_id : null}
                                        disabled={user.role !== "admin" && note && parseInt(note.note_status_id) > 4 ? true : false}
                                        fullWidth
                                    >
                                        {Object.entries(serviceNote.serviceNoteType).map(item => <MenuItem key={item[1]} value={item[0]}> {item[1]} </MenuItem>)}

                                    </Field>
                                </Grid>
                                <Grid item xs={accessScada && (user.role === "partner" && accessScada.partner_id !== 1) ? 6 : 4}>
                                    {user.role === "partner" && note && parseInt(note.note_status_id) > 4 ?
                                        <Field
                                            name="note_status_id"
                                            label="Status"
                                            type="text"
                                            component={Select}
                                            defaultValue={note && note.note_status_id ? note.note_status_id : null}
                                            fullWidth
                                            required
                                            disabled={user.role !== "admin" && note && parseInt(note.note_status_id) > 4 ? true : false}
                                        >
                                            {Object.entries(serviceNote.serviceNoteStatus).map(item => {
                                                return <MenuItem key={item[1]} value={item[0]}> {item[1]} </MenuItem>
                                            })
                                            }

                                        </Field>
                                        : <Field
                                            name="note_status_id"
                                            label="Status"
                                            type="text"
                                            component={Select}
                                            defaultValue={note && note.note_status_id ? note.note_status_id : null}
                                            fullWidth
                                            disabled={user.role !== "admin" && note && parseInt(note.note_status_id) > 4 ? true : false}
                                            required
                                        >
                                            {Object.entries(serviceNote.serviceNoteStatus).map(item => {
                                                if (user.role !== "partner")
                                                    return <MenuItem key={item[1]} value={item[0]}> {item[1]} </MenuItem>
                                                else if (user.role === "partner" && item[0] <= 4)
                                                    return <MenuItem key={item[1]} value={item[0]}> {item[1]} </MenuItem>
                                                else
                                                    return null
                                            })
                                            }

                                        </Field>
                                    }

                                </Grid>
                                {accessScada && (user.role === "partner" && accessScada.partner_id !== 1) ? null : <Grid item xs={4}>
                                    <Field
                                        name="method_of_notification"
                                        label="Sposób zgłoszenia"
                                        type="text"
                                        component={Select}
                                        defaultValue={note && note.method_of_notification ? note.method_of_notification : null}
                                        fullWidth
                                        disabled={user.role !== "admin" && note && parseInt(note.note_status_id) > 4 ? true : false}
                                    >
                                        {Object.entries(serviceNote.serviceNoteMethod).map(item => <MenuItem key={item[1]} value={item[0]}> {item[1]} </MenuItem>)}

                                    </Field>
                                </Grid>}
                                {user.role === "admin" && servicers ? <Grid item xs={4}>
                                    <Field
                                        name="executive_id"
                                        label="Wykonawca"
                                        type="text"
                                        component={Select}
                                        defaultValue={note && note.executive_id ? parseInt(note.executive_id) : 0}
                                        fullWidth
                                    >
                                        <MenuItem value={0}>Wybierz wykonawcę</MenuItem>
                                        {Object.entries(servicers).map(item => {
                                            return <MenuItem key={item[1].id} value={item[1].id}> {item[1].first_name} {item[1].last_name}</MenuItem>
                                        })
                                        }

                                    </Field>
                                </Grid> : null}


                            </Grid>
                            <Grid
                                container
                                direction="row">
                                <Grid item xs={12}>
                                {user.role !== "admin" && note && parseInt(note.note_status_id) > 4 ? null : <Button variant="contained" color="primary" onClick={() => handleClickOpen()}>Wybierz typ</Button>}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body1'><br />{parts.length > 0 ? 'Wybrane typy: ' : null}
                                        {parts.length > 0 ? parts.map(part => <span key={part.name.replaceAll(' ', '-')}> {part.name},</span>) : null}<br /><br /></Typography>
                                </Grid>
                            </Grid>
                            {note && noteContent ?
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        {noteContent.map(noteItem => {
                                            if (noteItem.visibility === true && accessScada.partner_id !== 1) {
                                                return null
                                            } else {
                                                let noteDate = new Date(noteItem.timestamp)
                                                return <div key={noteItem.timestamp}>
                                                    <p className="title-box"><span className={classes.floatLeft}><b>{dateConvert(noteDate, true).toLocaleString()}:</b>{noteItem.visibility ? ' (wewnętrzna)' : null}</span>
                                                        <span className={classes.floatRight}>{noteItem.user_email ? noteItem.user_email : `${noteItem.first_name} ${noteItem.last_name}`}</span></p>
                                                        { noteItem.endingInfoSend ? <p className="content-msg"><b>PODSUMOWANIE AKCJI SERWISOWEJ</b></p> : null }
                                                    <p className="content-msg" dangerouslySetInnerHTML={{ __html: noteItem.msg }}></p>
                                                    {noteItem.last_name ? <p className="content-msg"><strong>Zgłaszający:</strong>{noteItem.first_name && noteItem.last_name ? ` ${noteItem.first_name} ${noteItem.last_name}` : null}<br />
                                                        <strong>Tel.:</strong>{noteItem.phone ? ` ${noteItem.phone}` : null}<br />
                                                        <strong>E-mail:</strong>{noteItem.email ? ` ${noteItem.email}` : null}<br />
                                                        <strong>Adres montażu:</strong>{noteItem.street && noteItem.street_number && noteItem.city && noteItem.post_code ?
                                                            ` ${noteItem.street} ${noteItem.street_number}, ${noteItem.post_code} ${noteItem.city} ` : null}<br />
                                                        <strong>Nr j. zew.:</strong>{noteItem.outside_sn ? ` ${noteItem.outside_sn}` : " nie podano"}<br />
                                                        <strong>Zgoda na badanie satysfakcji:</strong>{noteItem.confirmation2 ? ` ${noteItem.confirmation2}` : "Brak zgody"}<br /></p> : null}
                                                </div>
                                            }
                                        })}
                                    </Grid>
                                    {user.role !== "admin" && note && parseInt(note.note_status_id) > 4 ? null : <Grid item xs={12}>
                                        <Field
                                            name="content"
                                            component={TextField}
                                            defaultValue={null}
                                            type="text"
                                            label="Notatka"
                                            fullWidth
                                            multiline
                                            minRows={4}
                                        />
                                    </Grid>}
                                    {accessScada.partner_id === 1 && note && !(user.role !== "admin" && note && parseInt(note.note_status_id) > 4) ?
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                label="Notatka wewnętrzna"
                                                control={
                                                    <Field
                                                        name="visibility"
                                                        component={Checkbox}
                                                        type="checkbox"
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid> : null}
                                </React.Fragment>
                                :
                                <Grid item xs={12}>
                                    <Field
                                        name="content"
                                        component={TextField}
                                        defaultValue={null}
                                        type="content"
                                        label="Notatka"
                                        fullWidth
                                        multiline
                                        required
                                        minRows={4}
                                    />
                                </Grid>}
                            <Grid item xs={12}>
                                <Typography variant='body1' style={{ color: "red" }}><br />{error}<br /></Typography>
                            </Grid>
                           {user.role !== "admin" && note && parseInt(note.note_status_id) > 4 ? null : <Grid item xs={12} className="buttons" style={{ height: "60px" }}>

                                {partner && partner_id !== 1 && note && note.hidden === false? <Button className={`${classes.floatLeft} ${classes.buttonMargin}`} type="submit"
                                    variant="contained" color="primary" disabled={send ? true : false}
                                    onClick={() => { form.change("sendEmail", true) }}>
                                    {send ? `Przekazano do ${accessScada && (user.role === "partner" && accessScada.partner_id !== 1) ? 'Euros Energy' : partner.name}`
                                        : `Przekaż do ${accessScada && (user.role === "partner" && accessScada.partner_id !== 1) ? 'Euros Energy' : partner.name}`} </Button> : null}

                                <Button id="mainSubmit" className={`${classes.floatRight} ${classes.buttonMargin}`}
                                    variant="contained" color="primary" type="submit"
                                    disabled={submitting}> Wyślij </Button>

                            </Grid>}
                        </form>
                    )}
                </Form>
                {user.role === "admin" && note ? <Form
                    initialValues={null}
                    onSubmit={onSubmit2}
                >
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid
                                container
                                direction="row">
                                <Grid
                                    container>
                                    <Grid item xs={12}>
                                        <Typography variant='h2'>Wiadomość do klienta</Typography>
                                        Uwaga! Jeśli wypełnione zostaną oba pola to obie wiadomości zostaną wysłane do klienta. Liczba znaków jest ograniczona.
                                    </Grid>
                                </Grid>
                                <Grid
                                    container>
                                    <Grid item xs={4}>
                                        <Field
                                            name="serviceStatusMessage"
                                            label="Informacja o statusie serwisu dla klienta"
                                            type="text"
                                            component={Select}
                                            defaultValue={serviceStatusMess.statusMsg}
                                            value={serviceStatusMess.statusMsg}
                                            onChange={handleChangeMessage}
                                            fullWidth
                                        >
                                            <MenuItem value={"Rozpoczęto zdalną diagnostykę. Proszę nie zmieniać nastaw pompy ciepła."}>Rozpoczęto zdalną diagnostykę. Proszę nie zmieniać nastaw pompy ciepła.</MenuItem>
                                            <MenuItem value={"Zakończono zdalną diagnostykę i skorygowano ustawienia. Urządzenie pracuje poprawnie."}>Zakończono zdalną diagnostykę i skorygowano ustawienia. Urządzenie pracuje poprawnie.</MenuItem>
                                            <MenuItem value={"Zakończono zdalną diagnostykę. Proszę oczekiwać na kontakt serwisanta."}>Zakończono zdalną diagnostykę. Proszę oczekiwać na kontakt serwisanta.</MenuItem>
                                        </Field>

                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            name="msgText"
                                            component={TextField}
                                            type="text"
                                            label="Wpisz treść"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button style={{ marginTop: "10px" }} variant="contained" color="primary" type="submit"
                                        >Wyślij push/sms</Button>
                                    </Grid>
                                    <Snackbar open={newDeviceStatus.phone.status}
                                        onClose={handleCloseSnackbarPhone}>
                                        <Alert onClose={handleCloseSnackbarPhone} severity={newDeviceStatus.phone.color}>
                                            {newDeviceStatus.phone.message}
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={newDeviceStatus.appPush.status}
                                        onClose={handleCloseSnackbarPush}>
                                        <Alert onClose={handleCloseSnackbarPush} severity={newDeviceStatus.appPush.color}>
                                            {newDeviceStatus.appPush.message}
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={newDeviceStatus.email.status}
                                        onClose={handleCloseSnackbarEmail}>
                                        <Alert onClose={handleCloseSnackbarEmail} severity={newDeviceStatus.email.color}>
                                            {newDeviceStatus.email.message}
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={newDeviceStatus.nullMessage.status}
                                        onClose={handleCloseSnackbarNullMessage}>
                                        <Alert onClose={handleCloseSnackbarNullMessage} severity={newDeviceStatus.nullMessage.color}>
                                            {newDeviceStatus.nullMessage.message}
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={!endingInfo.status && !endingInfo.state && newDeviceStatus.endingInfoSend.status}
                                        onClose={handleCloseSnackbarEndingInfo}>
                                        <Alert onClose={handleCloseSnackbarEndingInfo} severity={newDeviceStatus.endingInfoSend.color}>
                                            {newDeviceStatus.endingInfoSend.message}
                                        </Alert>
                                    </Snackbar>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form> : null}
            </Grid>

            <Dialog fullWidth={true}
                maxWidth={"sm"} open={open} onClose={handleCloseType} aria-labelledby="form-dialog-title">
                <DialogContent>

                    <Grid container>
                        <Grid item xs={9}><Typography variant="h3">Wybierz typ/komponenty</Typography></Grid>
                        <Grid item xs={3}>
                            <IconButton aria-label="close" onClick={handleCloseType} className={classes.floatRight}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <TypesParts handleCloseType={handleCloseType} handleSetParts={handleSetParts} partsList={partsList} chceckPart={parts} />
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>

            <Dialog fullWidth={true}
                maxWidth={"md"} open={endingInfo.status && !endingInfo.state} onClose={handleCloseType} aria-labelledby="form-dialog-title">
                <DialogContent>

                    <Grid container>
                        <Grid item xs={9}><Typography variant="h3">Wyślij podsumowanie do klienta po zakończeniu prac serwisowych.</Typography></Grid>
                        <Grid item xs={3}>
                            <IconButton aria-label="close" onClick={handleClickEndingInfoClose} className={classes.floatRight}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Form
                                initialValues={null}
                                onSubmit={onSubmit2}
                            >
                                {({ handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Grid
                                            container
                                            direction="row">
                                            <Grid
                                                container>

                                                <Grid item xs={8}>
                                                    <Field
                                                        name="msgText"
                                                        component={TextField}
                                                        type="text"
                                                        label="Wpisz treść"
                                                        fullWidth
                                                    />
                                                    <Field
                                                        name="endingInfoSend"
                                                        component={TextField}
                                                        type="hidden"
                                                        defaultValue={true}
                                                        InputProps = {{
                                                            hidden: true
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button style={{ marginTop: "10px" }} variant="contained" color="primary" type="submit"
                                                    >Wyślij push/sms</Button>
                                                </Grid>
                                                <Snackbar open={newDeviceStatus.phone.status}
                                                    onClose={handleCloseSnackbarPhone}>
                                                    <Alert onClose={handleCloseSnackbarPhone} severity={newDeviceStatus.phone.color}>
                                                        {newDeviceStatus.phone.message}
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar open={newDeviceStatus.appPush.status}
                                                    onClose={handleCloseSnackbarPush}>
                                                    <Alert onClose={handleCloseSnackbarPush} severity={newDeviceStatus.appPush.color}>
                                                        {newDeviceStatus.appPush.message}
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar open={newDeviceStatus.email.status}
                                                    onClose={handleCloseSnackbarEmail}>
                                                    <Alert onClose={handleCloseSnackbarEmail} severity={newDeviceStatus.email.color}>
                                                        {newDeviceStatus.email.message}
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar open={newDeviceStatus.nullMessage.status}
                                                    onClose={handleCloseSnackbarNullMessage}>
                                                    <Alert onClose={handleCloseSnackbarNullMessage} severity={newDeviceStatus.nullMessage.color}>
                                                        {newDeviceStatus.nullMessage.message}
                                                    </Alert>
                                                </Snackbar>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>

        </React.Fragment>
    )
}

export default AddServiceNote