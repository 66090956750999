import React, { useEffect, useState } from 'react'
import { Typography, Grid, TextField, Button } from '@material-ui/core'
import fetchDeviceUpdate from '../../../../data/fetchDeviceUpdate'
import { mainStyleClasses } from '../../../../theme'

const ContactDetails = ({ contactDetails, deviceId }) => {
    const classes = mainStyleClasses()
    const [detailsInfo, setDetailsInfo] = useState({ contact: {}, addInformation: "" })
// console.log(contactDetails.json())
    const handleDetailsInfo = (e) => {
        setDetailsInfo({
            ...detailsInfo,
            addInformation: e.target.value
        })
    }

    const sendContactDetails = () => {
        let sendChange = { id: deviceId, contact_details: JSON.stringify(detailsInfo ? detailsInfo : "") }
        fetchDeviceUpdate(sendChange).then(response => console.log(response))
    }

    useEffect(() => {
        let mounted = true
        if (contactDetails !== "")
            setDetailsInfo(JSON.parse(contactDetails))

        return () => {
            mounted = false
        }
    }, [contactDetails])

    return (
        <React.Fragment>
            <Grid container className='no-padding'>
                <Grid item xs={12}  className='no-padding'>
                    <TextField multiline variant="outlined"
                    minRows={4} name="customer_info" label="Informacje" type="text"
                        value={detailsInfo ? detailsInfo.addInformation : ""}
                        onChange={(e) => handleDetailsInfo(e)} />
                    <Button onClick={() => sendContactDetails()} variant="contained" color="primary" style={{float: "right"}} className={`${classes.floatLeft} ${classes.buttonMargin}`}>Zapisz</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ContactDetails