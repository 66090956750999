import React, { useState, useEffect } from 'react'
import { Grid, TextField, Button, Typography, IconButton, Snackbar, InputLabel, FormControl, Select, MenuItem, Fab, Menu }
    from '@material-ui/core'
import HardwareListItem from './HardwareListItem'
import AddIcon from "@material-ui/icons/Add"
import Tooltip from "@material-ui/core/Tooltip"
import fetchDeviceUpdate from '../../../../data/fetchDeviceUpdate'

const HardwareVersion = ({ hardware, deviceId, refreshDevice }) => {
    const [hardwareVers, setHardwareVers] = useState(hardware)
    const [fieldError, setFieldError] = useState(null)
    const [errorStatus, setErrorStatus] = useState(false)

    const errorCheck = (e) => {
        let errorInfo = false
        if (e === 'send')
            setFieldError(null)
        else {
            for (let index = 0; index < hardwareVers.length; index++) {

                if (hardwareVers[index].serial === "" || hardwareVers[index].name === "") {
                    errorInfo = true
                    if (hardwareVers[index].serial === "")
                        setFieldError({ ...fieldError, [`rowId${index}serial`]: { status: true, msg: "To pole jest wymagane" } })
                    else if (hardwareVers[index].name === "")
                        setFieldError({ ...fieldError, [`rowId${index}name`]: { status: true, msg: "To pole jest wymagane" } })
                }
            }
        }

        if (errorInfo)
            setErrorStatus(true)

        return errorInfo
    }

    const handleHardwareVers = () => {
        if (!errorCheck())
            setHardwareVers([...hardwareVers, { rowId: hardwareVers.length, name: "", serial: "" }])
    }

    const addHardwareVers = (e, i) => {
        let arrHelp = hardwareVers.slice()
        arrHelp[i][e.target.name] = e.target.value
        setHardwareVers(arrHelp)
        setFieldError({ ...fieldError, [`rowId${i}${e.target.name}`]: { status: false, msg: "" } })
    }

    const deleteHardwareVers = (e, i) => {
        let arrHlp = hardwareVers.filter(item => item.rowId !== i)

        setHardwareVers(arrHlp.length > 0 ? arrHlp : [{ rowId: 0, name: "", serial: "" }])
        setFieldError({ ...fieldError, [`rowId${i}${e.name}`]: { status: false, msg: "" } })
    }

    const sendHardwareVersion = () => {
        let payload = { id: deviceId }

        if (hardwareVers && hardwareVers.length > 0 && !errorCheck())
            payload = { ...payload, hardware_version: JSON.stringify(hardwareVers) }
        else if (hardwareVers && hardwareVers.length === 1 && !errorCheck('send'))
            payload = { ...payload, hardware_version: "" }

        // console.log({ payload })
        setFieldError(null)
        setErrorStatus(false)
        fetchDeviceUpdate(payload).then(response => {
            if (response.status === "success") {
                refreshDevice()
                setErrorStatus(false)
            } else {
                refreshDevice()
                setErrorStatus(true)
            }
        })
    }

    useEffect(() => {
        let mounted = true
        if (hardware)
            setHardwareVers(hardware)

        return () => {
            mounted = false
        }
    }, [hardware])


    return (
        <React.Fragment>
            <Grid container direction="row" style={{ marginTop: "30px" }}>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ display: 'inline' }}>Urządzenia dodatkowe</Typography>
                    <Tooltip title="Dodaj kolejne" placement="right-start">
                        <Fab
                            style={{ left: "1rem" }}
                            size="small"
                            color="primary"
                            onClick={() => handleHardwareVers()}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Grid>
            </Grid>
            {hardwareVers && hardware != "" ? hardwareVers.map((item, i) => <HardwareListItem deleteHardwareVers={deleteHardwareVers} index={i} fieldError={fieldError} key={i} addHardwareVers={addHardwareVers} hardwareItem={item} />) : null}
            <Grid container direction="row" >
                <Grid item xs={12}><br /><Button variant="contained" color="primary" type="submit" onClick={() => sendHardwareVersion()}>Zapisz urządzenia</Button>
                    {errorStatus ? <Typography variant="body1" style={{ color: "red", marginTop: "15px" }}>Wszystkie pola muszą być uzupełnione, żeby dodać urządzenia.</Typography> : null}</Grid>
            </Grid>
        </React.Fragment>
    )
}

export default HardwareVersion