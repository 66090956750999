import React from 'react'
import ListItem from '../../pages/DevicesList/ListItem'
import { dateConvert } from '../'

const filteredInstallationList = (itemList, filter, token, perm, subLogin, role, handleRefreshDevice, accessScada) => {
    let devices
    devices = itemList
    let devicesNewList

    if (filter.SNFilter === true) {
        devices = devices.filter(item => item.device_sn !== null && item.device_sn.includes(filter.SNValue) === true)
    }
    
    if (filter.keyFilter === true) {
        devices = devices.filter(item => item.key.includes(filter.keyValue) === true)
    }
    
    if (filter.customerFilter === true) {
        devices = devices.filter(item => ((item.installation.customer.phone.toUpperCase().includes(filter.customerValue) ||
                                            item.installation.customer.first_name.toUpperCase().includes(filter.customerValue) ||
                                            item.installation.customer.last_name.toUpperCase().includes(filter.customerValue)) === true))
    }
    
    if (filter.locationFilter === true) {
        devices = devices.filter(item => ((item.installation.customer.city.toUpperCase().includes(filter.locationValue) ||
                                            item.installation.customer.post_code.toUpperCase().includes(filter.locationValue)) === true))
    }
    
    if (filter.typeFilter === true) {
        switch (filter.typeValue) {
            case "ATMO":
                devices = devices.filter(item => (item.key.slice(0, 6) === "010101"));
                break;
            case "GEO":
                devices = devices.filter(item => (item.key.slice(0, 6) === "010201"));
                break;
            case "ALLINONE":
                devices = devices.filter(item => (item.key.slice(0, 6) === "010301"));
                break;
            case "GEOPLUS":
                devices = devices.filter(item => (item.key.slice(0, 6) === "010202"));
                break;
            case "EMS":
                devices = devices.filter(item => (item.key.slice(0, 6) === "020101"));
                break;
            case "PV":
                devices = devices.filter(item => (item.key.slice(0, 2) === "64"));
                break;
            case "PRZEMYSŁOWE":
                devices = devices.filter(item => (item.key.slice(0, 2) === "FA"));
                break;
            case "NIEZNANY":
                devices = devices.filter(item => (item.key.slice(0, 6) !== "010202" && item.key.slice(0, 2) !== "64" && item.key.slice(0, 2) !== "FA" && item.key.slice(0, 6) !== "010101" && item.key.slice(0, 6) !== "010201" && item.key.slice(0, 6) !== "010301" && item.key.slice(0, 6) !== "020101"));
                break;
            default:
                break;
        }
    }
    
    if (filter.onlineFilter === true) {
        switch (filter.onlineValue) {
            case "ONLINE":
                devices = devices.filter(item => {
                    if (item.last_telemetry_update) {
                        let dateNow = new Date()
                        dateNow = Date.now()
                        let dateUpdateTelemetry = new Date()
                        dateUpdateTelemetry = Date.parse(item.last_telemetry_update.slice(0, 19).replace("T", " "))
            
                        if (((dateNow - dateConvert(dateUpdateTelemetry)) / 1000 / 60) < 60) {
                            return true
                        } else {
                            return false
                        }
                    }
                });
                break;
            case "OFFLINE":
                devices = devices.filter(item => {
                    if (item.last_telemetry_update) {
                        let dateNow = new Date()
                        dateNow = Date.now()
                        let dateUpdateTelemetry = new Date()
                        dateUpdateTelemetry = Date.parse(item.last_telemetry_update.slice(0, 19).replace("T", " "))
            
                        if (((dateNow - dateUpdateTelemetry) / 1000 / 60) >= 60 && parseInt(item.last_telemetry_update.slice(0, 4)) >= 2019) {
                            return true
                        } else {
                            return false
                        }
                    }
                });
                break;
            case "NIGDY":
                devices = devices.filter(item => (!item.last_telemetry_update || parseInt(item.last_telemetry_update.slice(0, 4)) < 2019));
                break;
            case "ZAKRES":
                devices = devices.filter(item => {
                    if (item.last_telemetry_update) {
                        let dateFrom = new Date()
                        dateFrom = Date.parse(filter.onlineFrom)
                        let dateTo = new Date()
                        dateTo = Date.parse(filter.onlineTo)
                        let dateUpdateTelemetry = new Date()
                        dateUpdateTelemetry = Date.parse(item.last_telemetry_update.slice(0, 19).replace("T", " "))
            
                        if (dateUpdateTelemetry >= dateFrom && dateUpdateTelemetry <= dateTo) {
                            return true
                        } else {
                            return false
                        }
                    }
                });
                break;
            default:
                break;
        } 
    }
    
    if (filter.startFilter === true) {
        switch (filter.startValue) {
            case "ZAKRES":
                devices = devices.filter(item => {
                    if (item.first_telemetry_update) {
                        let dateFrom = new Date()
                        dateFrom = Date.parse(filter.startFrom)
                        let dateTo = new Date()
                        dateTo = Date.parse(filter.startTo)
                        let dateStartTelemetry = new Date()
                        dateStartTelemetry = Date.parse(item.first_telemetry_update.slice(0, 19).replace("T", " "))
            
                        if (dateStartTelemetry >= dateFrom && dateStartTelemetry <= dateTo) {
                            return true
                        } else {
                            return false
                        }
                    }
                });
                break;
            default:
                break;
        } 
    }
    
    if (filter.registrationFilter === true) {
        switch (filter.registrationValue) {
            case "ZAREJESTROWANE":
                devices = devices.filter(item => (parseInt(item.installation.customer_id) !== 1));
                break;
            case "NIEZAREJESTROWANE":
                devices = devices.filter(item => (parseInt(item.installation.customer_id) === 1));
                break;
            default:
                break;
        } 
    }

    if (filter.partnerFilter === true) {
        switch (filter.partnerValue) {
            case "EUROS":
                devices = devices.filter(item => (parseInt(item.partner_id) === 1));
                break;
            case "EON":
                devices = devices.filter(item => (parseInt(item.partner_id) === 2));
                break;
            case "POLENERGIA":
                devices = devices.filter(item => (parseInt(item.partner_id) === 40));
                break;
            case "RYNEK WTÓRNY":
                devices = devices.filter(item => (parseInt(item.partner_id) === 93));
                break;
            default:
                break;
        } 
    }

    devicesNewList = devices.map((item, index) => ( <ListItem accessScada={accessScada} role={role} subLogin={subLogin} perm={perm} key={item.key} index={index} item={item} token={token} handleRefreshDevice={handleRefreshDevice} />
    
    ))

    return devicesNewList
}

const sortList = (list, column, type, direction) => {
    let resultList = list;

    switch (type) {
        case "string":
            resultList = (direction === "asc" ? list.sort((a, b) => (a[column].toUpperCase() < b[column].toUpperCase() ? -1 : 1)) : list.sort((a, b) => (a[column].toUpperCase() > b[column].toUpperCase() ? -1 : 1)));
            break;
        case "number":
            resultList = (direction === "asc" ? list.sort((a, b) => a[column] - b[column]) : list.sort((a, b) => b[column] - a[column]));
            break;
        case "datetime":
            resultList = (direction === "asc" ? list.sort((a, b) => new Date(a[column]).getTime() - new Date(b[column]).getTime()) : list.sort((a, b) => new Date(b[column]).getTime() - new Date(a[column]).getTime()));
            break;
        default:
            resultList = list;
    }

    return resultList
}

const sortedInstallationList = (itemList, sort) => {
    if (sort.sortColumn === "none") {
        return itemList
    } else if (sort.sortColumn === "deviceType") {
        let newItemList = itemList.map(item => ({ ...item, "deviceType": (item.key.slice(0, 6) === "010101" ? "ATMO" 
        : (item.key.slice(0, 6) === "010201" ? "GEO" 
        : (item.key.slice(0, 6) === "010301" ? "ALLINONE" 
        : (item.key.slice(0,2) === "FA" ? "PRZEMYSŁOWE"
        : (item.key.slice(0,2) === "64" ? "PV"
        : (item.key.slice(0,6) === "010202" ? "GEOPLUS"
        : (item.key.slice(0,6) === "020101" ? "EMS"
        : "nieznany typ urządzenia")))))))
    }))
        return sortList(newItemList, "deviceType", "string", sort.direction)
    } else if (sort.sortColumn === "customerName") {
        let newItemList = itemList.map(item => ({ ...item, "customerName": item.installation.customer.last_name}))
        return sortList(newItemList, "customerName", "string", sort.direction)
    } else if (sort.sortColumn === "telemetryUpdate") {
        let newItemList = itemList.map(item => ({ ...item, "telemetryUpdate": !item.last_telemetry_update || parseInt(item.last_telemetry_update.slice(0, 4)) < 2019 ? 0 : item.last_telemetry_update.slice(0, 19)}))
        return sortList(newItemList, "telemetryUpdate", "datetime", sort.direction)
    } else if (sort.sortColumn === "telemetryStart") {
        let newItemList = itemList.map(item => ({ ...item, "telemetryStart": !item.first_telemetry_update || parseInt(item.first_telemetry_update.slice(0, 4)) < 2019 ? 0 : item.first_telemetry_update.slice(0, 19)}))
        return sortList(newItemList, "telemetryStart", "datetime", sort.direction)
    } else if (sort.sortColumn === "customerCity") {
        let newItemList = itemList.map(item => ({ ...item, "customerCity": item.installation.customer.city}))
        return sortList(newItemList, "customerCity", "string", sort.direction)
    }
}

const sortInstallationList = (name, setFilterDevice, filterDevice) => () => {
    switch (name) {
        case "deviceType":
            setFilterDevice({ type: "SORT", payload: { sortColumn: "deviceType", direction: filterDevice.sort.sortColumn === "deviceType" ? (filterDevice.sort.direction === "asc" ? "desc" : "asc") : "asc"}});
            break;
        case "customerName":
            setFilterDevice({ type: "SORT", payload: { sortColumn: "customerName", direction: filterDevice.sort.sortColumn === "customerName" ? (filterDevice.sort.direction === "asc" ? "desc" : "asc") : "asc"}});
            break;
        case "telemetryUpdate":
            setFilterDevice({ type: "SORT", payload: { sortColumn: "telemetryUpdate", direction: filterDevice.sort.sortColumn === "telemetryUpdate" ? (filterDevice.sort.direction === "asc" ? "desc" : "asc") : "asc"}});
            break;
        case "telemetryStart":
            setFilterDevice({ type: "SORT", payload: { sortColumn: "telemetryStart", direction: filterDevice.sort.sortColumn === "telemetryStart" ? (filterDevice.sort.direction === "asc" ? "desc" : "asc") : "asc"}});
            break;
        case "customerCity":
            setFilterDevice({ type: "SORT", payload: { sortColumn: "customerCity", direction: filterDevice.sort.sortColumn === "customerCity" ? (filterDevice.sort.direction === "asc" ? "desc" : "asc") : "asc"}});
            break;
        default:
            break;
    }
}

const filterTelemetryPeriod = (e, setFilterDevice, filterDevice) => {
    if (e.target.name === "telemetry-od")
    setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, startFrom: e.target.value } })
    else
    setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, startTo: e.target.value } })
}

const filterOnlinePeriod = (e, setFilterDevice, filterDevice) => {
    if (e.target.name === "online-od")
    setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, onlineFrom: e.target.value } })
    else
    setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, onlineTo: e.target.value } })
}

const filterInstallationList = (e, filter, setFilterDevice, filterDevice, setPage) => {
    setPage(0)
    switch(filter) {
        case "type":
            if (e.target.value === "WSZYSTKIE") {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, typeFilter: false, typeValue: "" } })
            } else {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, typeFilter: true, typeValue: e.target.value } })
            }

            break;
        case "SN":
            if (e.target.value === "") {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, SNFilter: false, SNValue: "" } })
            } else {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, SNFilter: true, SNValue: e.target.value.toUpperCase() } })
            }

            break;
        case "key":
            if (e.target.value === "") {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, keyFilter: false, keyValue: "" } })
            } else {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, keyFilter: true, keyValue: e.target.value.toUpperCase() } })
            }

            break;
        case "online":
            if (e.target.value === "WSZYSTKIE") {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, onlineFilter: false, onlineValue: "" } })
            } else {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, onlineFilter: true, onlineValue: e.target.value } })
            }

            break;
        case "start":
            if (e.target.value === "WSZYSTKIE") {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, startFilter: false, startValue: "" } })
            } else {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, startFilter: true, startValue: e.target.value } })
            }

            break;
        case "customer":
            if (e.target.value === "") {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, customerFilter: false, customerValue: "" } })
            } else {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, customerFilter: true, customerValue: e.target.value.toUpperCase() } })
            }

            break;
        case "location":
            if (e.target.value === "") {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, locationFilter: false, locationValue: "" } })
            } else {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, locationFilter: true, locationValue: e.target.value.toUpperCase() } })
            }

            break;
        case "registration":
            if (e.target.value === "") {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, registrationFilter: false, registrationValue: "" } })
            } else {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, registrationFilter: true, registrationValue: e.target.value } })
            }

            break;
        case "partner":
            if (e.target.value === "") {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, partnerFilter: false, partnerValue: "" } })
            } else {
                setFilterDevice({ type: "FILTERS", payload: { ...filterDevice.filters, partnerFilter: true, partnerValue: e.target.value } })
            }

            break;
        default:
            break;
    }
}

export const filtersandsort ={
    sortedInstallationList,
    sortList,
    filteredInstallationList,
    sortInstallationList,
    filterTelemetryPeriod,
    filterOnlinePeriod,
    filterInstallationList
}