import React, { useState } from 'react'
import { Grid, TextField, Button, Typography, IconButton, Snackbar, InputLabel, FormControl, Select, MenuItem, Fab, Menu }
    from '@material-ui/core'
import RemoveIcon from '@mui/icons-material/Remove'
import Tooltip from "@material-ui/core/Tooltip"

const HardwareListItem = ({ hardwareItem, addHardwareVers, fieldError, index, deleteHardwareVers }) => {

    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item xs={5}>
                    <TextField name="serial" label="Numer seryjny" type="text" value={hardwareItem.serial} onChange={(e) => addHardwareVers(e, index)}
                        error={fieldError && fieldError[`rowId${index}serial`] && fieldError[`rowId${index}serial`].status ? true : false} helperText={fieldError && fieldError[`rowId${index}serial`] && fieldError[`rowId${index}serial`].status ? fieldError[`rowId${index}serial`].msg : ""} />
                </Grid>
                <Grid item xs={5}>
                    <TextField name="name" label="Nazwa" type="text" value={hardwareItem.name} onChange={(e) => addHardwareVers(e, index)}
                        error={fieldError && fieldError[`rowId${index}name`] && fieldError[`rowId${index}name`].status ? true : false} helperText={fieldError && fieldError[`rowId${index}name`] && fieldError[`rowId${index}name`].status ? fieldError[`rowId${index}name`].msg : ""} />
                </Grid>
                <Grid item xs={2}>
                <Tooltip title="Usuń" placement="right-start">
                        <Fab
                            style={{ left: "1rem" }}
                            size="small"
                            color="primary"
                            onClick={() => deleteHardwareVers(hardwareItem, index)}
                        >
                            <RemoveIcon />
                        </Fab>
                    </Tooltip>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default HardwareListItem