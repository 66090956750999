import React, { useState, useEffect, useRef } from 'react'
import { Grid, Button, Dialog, DialogContent, Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'
import { pinEms } from '../../../../functions'
import { mainStyleClasses } from '../../../../theme'
import { mqttConfig } from "../../../../config/mqttConfig"
import { mqttClient } from "../../../../config/mqttClient"
import { functionPublish } from "../../../../config/functionPublish"
import fetchDeviceUpdate from '../../../../data/fetchDeviceUpdate'
import { errorsFunc } from './errors'

const expirationCheck = (time) => {
    let DateTime = new Date()
    let StampTime = new Date()
    DateTime = Date.now()
    StampTime = Date.parse(time)

    return (DateTime - StampTime)
}

const ActivationEMS = ({ hardware_version, keyM, contactDetails, deviceId }) => {
    const clientRef = useRef(null)
    const [hardware, setHardware] = useState()
    const [pinDialog, setPinDialog] = useState(false)
    const [messagesHelp, setMessagesHelp] = useState(null)
    const [token, setToken] = useState(null)
    const [tokenStatus, setTokenStatus] = useState(null)
    const [errorOfExpiration, seterrorOfExpiration] = useState(null)
    const [connectionStatus, setConnectionStatus] = useState(false)
    const [timer, setTimer] = useState(0)

    const classes = mainStyleClasses()

    const sendContactDetails = () => {
        let contactDetailsEms = { ...contactDetails, emsActivation: true }
        let sendChange = { id: deviceId, contact_details: JSON.stringify(contactDetailsEms ? contactDetailsEms : "") }
        fetchDeviceUpdate(sendChange).then(response => console.log(response))
    }

    const handleOpen = () => {
        connectionOn()
        setPinDialog(true)
    }

    const handleClose = () => {
        connectionClose()
        setPinDialog(false)
        clearInterval(intervalClear)
        setToken(null)
        setTokenStatus(null)
        seterrorOfExpiration(null)
        setTimer(0)
    }

    const changeTokenStatus = (token) => {
        let newTokenStatus
        newTokenStatus = token
        setToken(newTokenStatus)
    }

    const connectionClose = () => {
        clientRef.current.unsubscribe(`${keyM}/Dev`)
        clientRef.current.end()
    }

    const connectionOn = () => {
        clientRef.current = mqttClient.getClient(mqttConfig());

        clientRef.current.subscribe(`${keyM}/Dev`)
        clientRef.current.on("connect", () => setConnectionStatus(true))

        clientRef.current.on(
            "message",
            function (topic, message) {
                if (topic === `${keyM}/Dev` && JSON.parse(message).Devices) {
                    console.log(JSON.parse(message))
                    setMessagesHelp(JSON.parse(message))
                }
            },
            []
        )
    }

    const saveMessageFunc = (message, tokenStat) => {
        let DateTime = new Date()
        DateTime = Date.now()
        let tokenHlp

        if (tokenStat === null) {
            setTokenStatus(false)
            setTimeout(() => {
                tokenHlp = functionPublish(
                    clientRef.current,
                    { EMS_PIN: pinEms(keyM) },
                    keyM,
                    message.Devices[0],
                    changeTokenStatus
                )
            }, "1000")


        }


        if (expirationCheck(message.TimeStamp) > message.ExpirationTime + 3000) {
            seterrorOfExpiration(1)
        } else {
            seterrorOfExpiration(null)

            if (tokenStat !== true) {
                let newTokenStatus = null
                
                if ((`${token}` === message.Token)) {
                    newTokenStatus = true
                    connectionClose()
                    sendContactDetails()
                    setToken(null)
                } else {
                    newTokenStatus = false
                }

                setTokenStatus(newTokenStatus)
            }


        }
    }
     let intervalClear

const intervalFunc = () => {
    const interval = setInterval(() => {
        setTimer(timer + 1000)
        if (timer >= messagesHelp.ExpirationTime + 3000) {
            connectionClose()
            seterrorOfExpiration(2)
            setConnectionStatus(false)
            setToken(null)
        }

    }, 1000)

    return interval
}

    useEffect(() => {
        let mounted = true
    
        if (tokenStatus === false && token) {     
            intervalClear = intervalFunc()
        } else if(intervalClear){
            clearInterval(intervalClear)
        }

        return () => {
            mounted = false
            clearInterval(intervalClear)
        }
    }, [timer, tokenStatus, token])


    useEffect(() => {
        let mounted = true
        if (hardware_version !== "")
            setHardware(JSON.parse(hardware_version))
            else
            setHardware([])

        return () => {
            mounted = false
        }
    }, [hardware_version])

    useEffect(() => {
        let mounted = true
        if (messagesHelp)
            saveMessageFunc(messagesHelp, tokenStatus)

        return () => {
            mounted = false
        }
    }, [messagesHelp])


    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    {hardware && hardware.length > 0 && hardware.find(item => item.name === "METER_1") ?
                    <Button variant="contained" className={tokenStatus || (contactDetails && contactDetails.emsActivation) ? classes.backgroundButtonGreen : classes.backgroundButtonBlue} color="primary" onClick={() => handleOpen()} >{tokenStatus || (contactDetails && contactDetails.emsActivation) ? "EMS aktywowany" : "Aktywacja EMS"}</Button>
                        : <Button variant="contained" color="primary" disabled>Brak liczników</Button>}
                    {/*<Button variant="contained" className={tokenStatus || (contactDetails && contactDetails.emsActivation) ? classes.backgroundButtonGreen : classes.backgroundButtonBlue} color="primary" onClick={() => handleOpen()} >{tokenStatus || (contactDetails && contactDetails.emsActivation) ? "EMS aktywowany" : "Aktywacja EMS"}</Button> DO TESTÓW */}
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"lg"} open={pinDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99" }}>
                        {errorOfExpiration ? <CloseIcon />
                            : tokenStatus === null || tokenStatus !== true ?
                                null : <CloseIcon />}
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}>
                            {errorOfExpiration ? <Typography variant='h4'>{errorsFunc(errorOfExpiration, messagesHelp.TimeStamp)}</Typography>
                                : tokenStatus === null || tokenStatus !== true ?
                                    <React.Fragment><Typography variant='h4'>Trwa przetwarzanie, nie wyłączaj okna</Typography> <CircularProgress /></React.Fragment>
                                    : <Typography variant='h4'>EMS został aktywowany</Typography>}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default ActivationEMS