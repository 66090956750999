import {PublishSetupModule} from './tokenBuild'

export const functionPublish = (client, Data, installation, device, changeTokenStatus) => {
  let publishDataToken1 = Math.floor(Math.random() * (999999999 - 100000000 + 1) + 100000000)
  if(changeTokenStatus)
  changeTokenStatus(publishDataToken1)
    //   client.publish(`${installation}/App`, PublishSetupModule.GetJson((JSON.stringify({
    //     Data,
    //     "Token": Math.floor(Math.random() * (999999999 - 100000000 + 1) + 100000000)
    // }), device.Name, device.Type, device.Version))


    if(Array.isArray(Data)) {
      let values = []
      let names = []
      let types = []
      let versions = []
      Data.map((item, i) => {
        values.push(JSON.stringify({ Data: item, "Token": publishDataToken1 }))
        names.push(device[i].Name)
        types.push(device[i].Type)
        versions.push(device[i].Version)
      })

      client.publish(`${installation}/App`, PublishSetupModule(values, names, types, versions))

      console.log(`${installation}/App`, PublishSetupModule(values, names, types, versions))
    } else {
      client.publish(`${installation}/App`, PublishSetupModule(JSON.stringify({
        Data,
         "Token": publishDataToken1
       }), device.Name, device.Type, device.Version))
   
       console.log(`${installation}/App`, PublishSetupModule(JSON.stringify({
         Data,
          "Token": publishDataToken1
        }), device.Name, device.Type, device.Version))
    }

    return publishDataToken1
  }