import React, { useReducer, useEffect, useState } from 'react'
import { Redirect, useParams, useLocation } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'
import { Menu, Button, Grid, Dialog, DialogContent, IconButton, Typography, InputLabel, MenuItem, FormControl, Select, Snackbar, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../theme'
import fetchAcceptanceReport from '../../data/fetchAcceptanceReport'
import fetchDevice from '../../data/fetchDevice'
import { fetchPush, fetchPin } from '../../data'
import { Link } from 'react-router-dom'
import { Diagnostics, ContactDetails, Inspections, HardwareVersion, ActivationEMS, ViewAcceptanceReport, CustomerContact, DeviceDetails, ServiceNotes, Insurance, Warranty } from './compotents'
import { DeviceChangeLog } from '../../components'
import { fetchAddDevice, fetchPartnersId, fetchDistribution, fetchAddPv } from '../../data'

const Alert = (props) => {
    return <MuiAlert elevation={2} variant="filled" {...props} />;
}

const moreInformationReducer = (state, action) => {
    switch (action.type) {
        case "load":
            return {
                ...state,
                [action.type]: action.payload
            }

        case "acceptanceReport":
            return {
                ...state,
                acceptanceReport: action.payload
            }

        case "loadAR":
            return {
                ...state,
                loadAR: action.payload
            }

        case "device":
            return {
                ...state,
                installation: action.payload
            }

        case "customer":
            return {
                ...state,
                customerId: action.payload
            }

        case "customerAll":
            return {
                ...state,
                customer: action.payload
            }

        default:
            return { ...state }
    }
}

const Device = ({ token, setBaseLogin, perm, user, role, accessScada }) => {

    const newDeviceReducer = (state, action) => {
        return {
            ...state,
            [action.type]: action.payload
        }
    }

    const classes = mainStyleClasses()
    let location = useLocation()

    const [moreInformation, setMoreInformation] = useReducer(moreInformationReducer, { load: false, acceptanceReport: null, loadAR: false, installation: null })
    const [serviceStatusMess, setServiceStatusMess] = useState('')
    const [newDeviceStatus, setDeviceStatus] = useState({ status: false, message: "" })
    const [pin, setPin] = useState(null)
    const [dignostics, setDiagnostics] = useState(false)
    const [partnerInfo, setPartnerInfo] = useState(null)

    const [warrantyInfo, setWarrantyInfo] = useState(null)

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseAnchor = () => {
        setAnchorEl(null)
    }

    const handleOpen = () => {
        setDiagnostics(true)
    }
    const handleClose = () => {
        setDiagnostics(false)
    }

    const handleEsc = (e) => {
        if (e.key === "Escape")
            handleClose()
    }

    const handleChangeMessage = (event) => {
        setServiceStatusMess(event.target.value)
    }

    let { deviceSN, keyM } = useParams();

    const updateTelemetryColor = () => {

        if (moreInformation && moreInformation.installation) {
            let dateNow = new Date()
            dateNow = Date.now()
            let dateUpdateTelemetry
            if (moreInformation.installation.last_telemetry_update) {
                dateUpdateTelemetry = new Date()
                dateUpdateTelemetry = Date.parse(moreInformation.installation.last_telemetry_update.slice(0, 19).replace("T", " "))
            } else {
                dateUpdateTelemetry = "nieznana"
            }

            if (((dateNow - dateUpdateTelemetry) / 1000 / 60) < 60) {
                return classes.backgroundGreen
            } else if (moreInformation.installation.last_telemetry_update === null || (parseInt(moreInformation.installation.last_telemetry_update.slice(0, 4)) < 2019)) {
                return classes.backgroundRed
            } else {
                return classes.backgroundYellow
            }
        }
    }

    const refreshAcceptanceReport = () => {

        if (deviceSN && !moreInformation.load) {
            fetchAcceptanceReport(token, deviceSN)
                .then(response => {
                    if (response.ok) {
                        setMoreInformation({ type: "load", payload: true })
                        return response.json()
                    } else {
                        throw new Error(response.status)
                    }
                }).then(response => {
                    setMoreInformation({ type: "acceptanceReport", payload: { content: JSON.parse(response.content), reportId: response.id, status: response.status } })
                })
                .catch(error => {
                    if (error.message === "403") {
                        setBaseLogin({ type: "token", payload: null })
                        localStorage.removeItem("APIToken")
                    } else {
                        setMoreInformation({ type: "loadAR", payload: false })
                    }
                })
        }
    }

    const handleSendPush = (text, title) => {
        fetchPush({ key: keyM, message: text, title: title })
            .then(response => {
                if (response.status === "ok") {
                    setDeviceStatus({ status: true, message: "Wiadomość została wysłana do klienta przez aplikację mobilną.", color: "success" })
                    setServiceStatusMess('')
                } else {
                    setDeviceStatus({ status: true, message: "Nie udało się wysłać wiadomości do klienta przez aplikację mobilną.", color: "error" })
                    setServiceStatusMess('')
                }
            })
    }

    const refreshDevice = () => {
        if (keyM) {
            fetchDevice(token, keyM)
                .then(response => {
                    setMoreInformation({ type: "device", payload: response })
                })
        }
    }

    useEffect(() => {
        let mounted = true
        if (keyM) {
            fetchDevice(token, keyM)
                .then(response => {
                    if (mounted)
                        setMoreInformation({ type: "device", payload: response })
                })
        }
        refreshAcceptanceReport()
        return function cleanup() {
            mounted = false
        }
    }, [deviceSN, keyM, token, moreInformation.load, setBaseLogin])


    const handleAcceptanceReport = () => {
        setMoreInformation({ type: "loadAR", payload: !moreInformation.loadAR })
    }
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeviceStatus({ status: false })
    }

    useEffect(() => {
        let mounted = true
        setPin(null)
        return () => {
            mounted = false
        }
    }, [deviceSN])

    const downloadPin = () => {
        fetchPin(deviceSN).then(response => setPin(response))
    }

    useEffect(() => {
        let mounted = true
        if (location && location.state)
            setPartnerInfo(location.state.deviceState.partner)
        return () => {
            mounted = false
        }
    }, [location])

    return (
        <React.Fragment>
            {!token ? <Redirect to="/" /> : null}
            <Grid container direction="row" >
                <Grid item xs={12} >
                    <Typography variant="h1">Instalacja: {deviceSN}</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item xs={6} >
                    <DeviceDetails refreshDevice={refreshDevice} partner={partnerInfo ? partnerInfo : null} role={role} colorTelemetryUpdate={updateTelemetryColor()} perm={perm} details={moreInformation && moreInformation.installation ? moreInformation.installation : null} setBaseLogin={setBaseLogin} token={token} /><br />
                    {moreInformation && moreInformation.installation ?
                        moreInformation.installation.p1 === 2 || moreInformation.installation.p1 === 250 || moreInformation.installation.p1 === 100 ?
                            null
                            : <Grid container style={{ margin: "30px 0 0 0" }}>
                                <Grid item xs={9}>
                                    <Typography variant="h2" style={{ margin: 0 }}>Pin do aplikacji Euros Monitor</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    {pin && pin.status === "ok" ?
                                        <Typography variant="h2" style={{ margin: 0 }}>{pin.data.pin}</Typography>
                                        : <Button variant="contained" color="primary" type="button"
                                            onClick={() => downloadPin()}>Pobierz PIN</Button>}
                                </Grid>
                            </Grid>
                        : null
                    }
                </Grid>
                <Grid item xs={6} >
                    {moreInformation && moreInformation.installation ?
                        <React.Fragment>
                            <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                Aplikacje do sterowania
                            </Button>
                            <Menu
                                id="menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleCloseAnchor}
                                className={`${classes.floatRight}`}
                            >
                                {moreInformation.installation.p1 === 100 ?
                                    null
                                    : <MenuItem onClick={handleOpen}>
                                        <Link>Diagnostyka</Link>
                                    </MenuItem>
                                }
                                {user.role === "admin" || user.role === "standard_viewer" ?
                                    moreInformation.installation.p1 === 2 || moreInformation.installation.p1 === 250 || moreInformation.installation.p1 === 100 ?
                                        null
                                        : <MenuItem
                                            component={Link}
                                            to={{
                                                pathname: `/euros-monitor/${keyM}/${btoa(moreInformation.installation.bro_pass)}`,
                                                state: {
                                                    sn: deviceSN, token: token,
                                                    installation_id: moreInformation.installation.installation_id,
                                                    telemetry: moreInformation.installation.last_telemetry_update
                                                }
                                            }}
                                        >
                                            Euros Monitor
                                        </MenuItem>
                                    : null
                                }
                                {moreInformation.installation.p1 === 2 || moreInformation.installation.p1 === 250 || moreInformation.installation.p1 === 100 ?
                                    null
                                    : <MenuItem
                                        component={Link}
                                        to={{
                                            pathname: `/online-management/${keyM}/${btoa(moreInformation.installation.bro_pass)}`,
                                            state: {
                                                sn: deviceSN, token: token,
                                                installation_id: moreInformation.installation.installation_id,
                                                telemetry: moreInformation.installation.last_telemetry_update
                                            }
                                        }}
                                    >
                                        Kliencka niebieska
                                    </MenuItem>
                                }
                                {moreInformation.installation.p1 === 2 || moreInformation.installation.p1 === 250 || moreInformation.installation.p1 === 100 ?
                                    null
                                    : <MenuItem
                                        component={Link}
                                        to={{
                                            pathname: `/euros-box/${keyM}/${btoa(moreInformation.installation.bro_pass)}`,
                                            state: {
                                                sn: deviceSN, token: token,
                                                installation_id: moreInformation.installation.installation_id,
                                                telemetry: moreInformation.installation.last_telemetry_update
                                            }
                                        }}

                                    >
                                        Kliencka czarna
                                    </MenuItem>
                                }
                                {user.role === "admin" ?
                                    moreInformation.installation.p1 === 100 ?
                                        null
                                        : <MenuItem
                                            component={Link}
                                            to={{
                                                pathname: `/register-list/${keyM}/${btoa(moreInformation.installation.bro_pass)}`,
                                                state: {
                                                    sn: deviceSN, token: token,
                                                    installation_id: moreInformation.installation.installation_id,
                                                    telemetry: moreInformation.installation.last_telemetry_update
                                                }
                                            }}
                                        >
                                            Rejestry
                                        </MenuItem>
                                    : null
                                }
                            </Menu>
                        </React.Fragment>
                        : null}
                    {/*moreInformation && moreInformation.installation ?
                        <React.Fragment>
                            {moreInformation.installation.p1 === 100 ?
                                null
                                : <Button variant="contained" color="primary" style={{ margin: "0 15px 20px 0" }} onClick={handleOpen}>
                                    Diagnostyka
                                </Button>
                            }
                            {user.role === "admin" || user.role === "standard_viewer" ?
                                moreInformation.installation.p1 === 2 || moreInformation.installation.p1 === 250 || moreInformation.installation.p1 === 100 ?
                                    null
                                    : <Button variant="contained" color="primary" style={{ margin: "0 15px 20px" }}>
                                        <Link
                                            className="white-link"
                                            to={{
                                                pathname: `/euros-monitor/${keyM}/${btoa(moreInformation.installation.bro_pass)}`,
                                                state: {
                                                    sn: deviceSN, token: token,
                                                    installation_id: moreInformation.installation.installation_id,
                                                    telemetry: moreInformation.installation.last_telemetry_update
                                                }
                                            }}
                                        >
                                            Euros Monitor
                                        </Link>
                                    </Button>
                                : null
                            }
                            {moreInformation.installation.p1 === 2 || moreInformation.installation.p1 === 250 || moreInformation.installation.p1 === 100 ?
                                null
                                : <Button variant="contained" color="primary" style={{ margin: "0 15px 20px" }}>
                                    <Link
                                        className="white-link"
                                        to={{
                                            pathname: `/online-management/${keyM}/${btoa(moreInformation.installation.bro_pass)}`,
                                            state: {
                                                sn: deviceSN, token: token,
                                                installation_id: moreInformation.installation.installation_id,
                                                telemetry: moreInformation.installation.last_telemetry_update
                                            }
                                        }}
                                    >
                                        Kliencka niebieska
                                    </Link>
                                </Button>
                            }
                            {moreInformation.installation.p1 === 2 || moreInformation.installation.p1 === 250 || moreInformation.installation.p1 === 100 ?
                                null
                                : <Button variant="contained" color="primary" style={{ margin: "0 15px 20px" }}>
                                    <Link
                                        className="white-link"
                                        to={{
                                            pathname: `/euros-box/${keyM}/${btoa(moreInformation.installation.bro_pass)}`,
                                            state: {
                                                sn: deviceSN, token: token,
                                                installation_id: moreInformation.installation.installation_id,
                                                telemetry: moreInformation.installation.last_telemetry_update
                                            }
                                        }}

                                    >
                                        Kliencka czarna
                                    </Link>
                                </Button>
                            }
                            {user.role === "admin" ?
                                moreInformation.installation.p1 === 100 ?
                                    null
                                    : <Button variant="contained" color="primary" style={{ margin: "0 15px 20px" }}>
                                        <Link
                                            className="white-link"
                                            to={{
                                                pathname: `/register-list/${keyM}/${btoa(moreInformation.installation.bro_pass)}`,
                                                state: {
                                                    sn: deviceSN, token: token,
                                                    installation_id: moreInformation.installation.installation_id,
                                                    telemetry: moreInformation.installation.last_telemetry_update
                                                }
                                            }}
                                        >
                                            Rejestry
                                        </Link>
                                    </Button>
                                : null
                            }
                        </React.Fragment> : null*/}

                    {updateTelemetryColor() && updateTelemetryColor().slice(21, -2) !== "Green" && updateTelemetryColor().slice(21, -2) === "Red" ?
                        <p>Uwaga: instalacja nigdy nie była podłączona do internetu. Nie posiadamy żadnych danych z pracy urządzenia.</p>
                        : (updateTelemetryColor() && updateTelemetryColor().slice(21, -2) !== "Green" && updateTelemetryColor().slice(21, -2) === "Yellow" ? <p>Uwaga: instalacja nie była widoczna online od dłuższego czasu. Dane dostępne w widoku sterowania pompą są ostatnimi
                            wysłanymi przez instalację. Mogą być nieaktualne. Sterowanie online prawdopodobnie jest niemożliwe. Proproś klienta
                            o podłączenie instalacji do internetu.</p> : null)}
                    {moreInformation && moreInformation.installation ?
                        <React.Fragment><CustomerContact setMoreInformation={setMoreInformation} role={user.role}
                            address={moreInformation.acceptanceReport ? moreInformation.acceptanceReport : null}
                            token={token} installation_id={moreInformation.installation.installation_id} />
                            {user.role === "admin" ? <ActivationEMS deviceId={moreInformation && moreInformation.installation ?
                                moreInformation.installation.id : null} contactDetails={moreInformation && moreInformation.installation && moreInformation.installation.contact_details?
                                    JSON.parse(moreInformation.installation.contact_details) : ""}
                                keyM={keyM} hardware_version={moreInformation.installation.hardware_version} /> : null}</React.Fragment>
                        : null}
                    <Button style={{ marginTop: "30px" }} variant="contained" color="primary"
                        onClick={() => handleAcceptanceReport()}>Protokół pierwszego uruchomienia</Button>

                    {user.role === "admin" ? <Grid container>
                        <Grid item xs={8}>
                            <FormControl style={{ margin: "20px -15px" }}>
                                <InputLabel id="serviceStatus">Informacja o statusie serwisu dla klienta</InputLabel>
                                <Select
                                    labelId="serviceStatus"
                                    id="serviceStatusMessage"
                                    value={serviceStatusMess}
                                    onChange={handleChangeMessage}
                                >
                                    <MenuItem value={"Rozpoczęto zdalną diagnostykę. Proszę nie zmieniać nastaw pompy ciepła."}>Rozpoczęto zdalną diagnostykę. Proszę nie zmieniać nastaw pompy ciepła.</MenuItem>
                                    <MenuItem value={"Zakończono zdalną diagnostykę i skorygowano ustawienia. Urządzenie pracuje poprawnie."}>Zakończono zdalną diagnostykę i skorygowano ustawienia. Urządzenie pracuje poprawnie.</MenuItem>
                                    <MenuItem value={"Zakończono zdalną diagnostykę. Proszę oczekiwać na kontakt serwisanta."}>Zakończono zdalną diagnostykę. Proszę oczekiwać na kontakt serwisanta.</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item xs={4}>
                            <Button style={{ marginTop: "30px" }} variant="contained" color="primary"
                                onClick={() => handleSendPush(serviceStatusMess, "Informacja serwisowa")}>Wyślij push</Button>
                        </Grid>
                        <Snackbar open={newDeviceStatus.status} anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            onClose={handleCloseSnackbar}>
                            <Alert onClose={handleCloseSnackbar} severity={newDeviceStatus.color}>
                                {newDeviceStatus.message}
                            </Alert>
                        </Snackbar>
                    </Grid> : null}
                    <Grid container>
                        <Grid item xs={12} className='no-padding'>
                            <ContactDetails deviceId={moreInformation && moreInformation.installation ? moreInformation.installation.id : null} contactDetails={moreInformation && moreInformation.installation ? moreInformation.installation.contact_details : ""} />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} className='no-padding' style={{ marginBottom: "30px" }}>
                            <Insurance refreshDevice={refreshDevice} deviceId={moreInformation && moreInformation.installation ? moreInformation.installation.id : null}
                                insurance_start_date={moreInformation && moreInformation.installation ? moreInformation.installation.insurance_start_date : null}
                                insurance_end_date={moreInformation && moreInformation.installation ? moreInformation.installation.insurance_end_date : null}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} className='no-padding'>
                            <Warranty partner={partnerInfo ? partnerInfo : null} warrantyInfo={warrantyInfo} refreshDevice={refreshDevice} deviceId={moreInformation && moreInformation.installation ? moreInformation.installation.id : null}
                                warranty_date={moreInformation && moreInformation.installation ? moreInformation.installation.warranty_date : null}
                                client_launch_date={moreInformation && moreInformation.installation ? moreInformation.installation.client_launch_date : null}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} className='no-padding'>
                            <Inspections refreshDevice={refreshDevice} setWarrantyInfo={setWarrantyInfo} deviceId={moreInformation && moreInformation.installation ? moreInformation.installation.id : null}
                                warranty_date={moreInformation && moreInformation.installation ? moreInformation.installation.warranty_date : null}
                                client_launch_date={moreInformation && moreInformation.installation ? moreInformation.installation.client_launch_date : null}
                            />
                        </Grid>
                    </Grid>

                </Grid>

                {moreInformation && moreInformation.installation && moreInformation.installation.device_log ?
                    <Grid item xs={12}> <hr /><DeviceChangeLog deviceLog={moreInformation.installation.device_log} /></Grid> : null}
                <Grid item xs={12}><hr />
                    <ServiceNotes partner={partnerInfo ? partnerInfo : null} role={role} accessScada={accessScada} partner_id={moreInformation && moreInformation.installation ? moreInformation.installation.partner_id : null} user={user} perm={perm}
                        token={token} keyM={keyM} deviceSerial={deviceSN} customerId={moreInformation.customerId ? moreInformation.customerId : null}
                        deviceId={moreInformation.installation ? moreInformation.installation.id : null} customer={moreInformation.customer ? moreInformation.customer : null} />
                </Grid>
            </Grid>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={moreInformation.loadAR} onClose={handleAcceptanceReport} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <IconButton aria-label="close" onClick={handleAcceptanceReport} className={classes.floatRight} style={{ zIndex: "99" }}>
                        <CloseIcon />
                    </IconButton>
                    {/*moreInformation.loadAR && moreInformation.acceptanceReport && moreInformation.acceptanceReport.status === "accept" ?
                        <ViewAcceptanceReport refreshAcceptanceReport={refreshAcceptanceReport} deviceSerial={deviceSN} reportId={moreInformation.acceptanceReport.reportId} report={moreInformation.acceptanceReport.content} />
                        : moreInformation.acceptanceReport && moreInformation.acceptanceReport.status === "new" ?
                            <p>Rozpoczęto konfigurację urządzenia. Proszę czekać na dokończenie protokołu uruchomienia przez instalatora.</p>
                            : moreInformation.acceptanceReport && moreInformation.acceptanceReport.status === "filled" ?
                                <React.Fragment><p>Protokół został wypełniony przez instalatora. Klient nie zaakceptował jeszcze protokołu.</p><ViewAcceptanceReport refreshAcceptanceReport={refreshAcceptanceReport} deviceSerial={deviceSN} reportId={moreInformation.acceptanceReport.reportId} report={moreInformation.acceptanceReport.content} /></React.Fragment>
                        : <p>Instalator nie rozpoczął konfiguracji urządzenia.</p>*/}

                    {moreInformation.loadAR && moreInformation.acceptanceReport ?
                        <ViewAcceptanceReport refreshAcceptanceReport={refreshAcceptanceReport} deviceSerial={deviceSN} keyM={keyM} reportId={moreInformation.acceptanceReport.reportId} report={moreInformation.acceptanceReport.content} />
                        : <p>Protokół pierwszego uruchomienia nie został wypełniony.</p>}
                </DialogContent>
            </Dialog>
            <Dialog onKeyDown={(e) => handleEsc(e)} scroll={"body"} fullWidth={true} maxWidth={"lg"} open={dignostics} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Diagnostics installation={keyM} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default Device