import { sha256 } from 'js-sha256'

const toHexString = (byteArray) => {
    return Array.prototype.map
      .call(byteArray, function (byte) {
        // eslint-disable-next-line no-bitwise
        return ('0' + (byte & 0xff).toString(16)).slice(-2);
      })
      .join('');
  }

 const toByteArray = (hexString) => {
    var result = [];
    for (var i = 0; i < hexString.length; i += 2) {
      result.push(parseInt(hexString.substr(i, 2), 16));
    }
    return new Uint8Array(result);
  }

export const pinEms = (key) => {
    let salt = '|ems'
    let pinLen = 1000000 //ilosc 0 determinuje dlugosc pinu, aktualnie bedzie to pin 4 cyfrowy
    let pin = key + salt

    pin = sha256(pin)
    pin = pin.slice(0,8)

    const byteArray = new Uint8Array(toByteArray(pin)).reverse()

    return  parseInt(toHexString(byteArray), 16) % pinLen
}