import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography, InputLabel, MenuItem, FormControl, Select, Snackbar, TextField, Table, Paper, TableBody, TableContainer, TableRow, TableCell, TableHead, Box, InputAdornment } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import plLocale from "date-fns/locale/pl"
import { fetchDeviceUpdate } from '../../../../data'

const warrantyCheck = (warrantyDate) => {
    let checkDate = new Date(warrantyDate) - new Date()

    if (checkDate < 0)
        return true
    else return false

}

const Warranty = ({ warranty_date, client_launch_date, deviceId, refreshDevice, warrantyInfo, partner }) => {
    const [warrantyDate, setWarrantyDate] = useState(new Date())
    const [clientLaunchDate, setClientLaunchDate] = useState(new Date())
    const [addWarranty, setAddWarranty] = useState(false)

    const dateCheck = (e, typeDate) => {
        let helpClientLaunchDate = new Date(clientLaunchDate)
        let helpWarrantyDate = new Date(warrantyDate)
        switch (typeDate) {
            case "warrantyDate":

                if (e < helpClientLaunchDate)
                    return setWarrantyDate(new Date(helpClientLaunchDate.setDate(helpClientLaunchDate.getDate() + 1)))
                else
                    return setWarrantyDate(new Date(e))

            case "clientLaunchDate":

                if (e > helpWarrantyDate)
                    return setClientLaunchDate(new Date(helpWarrantyDate.setDate(helpWarrantyDate.getDate() - 1)))
                else
                    return setClientLaunchDate(new Date(e))

            default:
                break;
        }
    }

    const handleAddWarranty = () => {
        let helpDate = new Date()
        
        let dateClientLaunchDate = client_launch_date && parseInt(client_launch_date.slice(0, 4)) < 2000 ? helpDate : client_launch_date

        setAddWarranty(true)

        if (client_launch_date) {
            setClientLaunchDate(new Date(dateClientLaunchDate))
        } else {
            setClientLaunchDate(new Date())
        }
        if (warranty_date) {
            let dateWarranty = parseInt(warranty_date.slice(0, 4)) < 2000 ? dateClientLaunchDate ? new Date(dateClientLaunchDate.setMonth(dateClientLaunchDate.getMonth() + 24)) : new Date(helpDate.setMonth(helpDate.getMonth() + 24)) : new Date(helpDate.setMonth(helpDate.getMonth() + 24))
            setWarrantyDate(new Date(dateWarranty))
        } else {
            setWarrantyDate(new Date(helpDate.setMonth(helpDate.getMonth() + 24)))
        }

    }

    const handleChangeWarrantyDate = () => {
        let payload = {
            id: deviceId,
            warranty_date: warrantyDate.toISOString().slice(0, 19),
            client_launch_date: clientLaunchDate.toISOString().slice(0, 19)
        }

        fetchDeviceUpdate(payload).then(response => refreshDevice(response))
    }

    useEffect(() => {
        let mounted = true
        if (warrantyInfo)
            refreshDevice()

        if (warranty_date && client_launch_date) {
            setWarrantyDate(new Date(warranty_date))
            setClientLaunchDate(new Date(client_launch_date))
        } else {
            setWarrantyDate(new Date())
            setClientLaunchDate(new Date())
        }


        return () => {
            mounted = false
        }
    }, [warranty_date, warrantyInfo, client_launch_date])

    return (
        <Grid container>
            <Grid item xs={2} className='no-padding'>
                <Typography variant='h3' style={{ color: (warrantyCheck(warranty_date) && addWarranty === false) || warrantyInfo ? "red" : "green", marginTop: "15px" }}>Gwarancja<br /><br /></Typography>
            </Grid>

            {(warrantyCheck(warrantyDate) && addWarranty === false) || warrantyInfo ? <Grid item xs={6}><Typography variant="h3" style={{ marginTop: "15px" }}><span style={{ color: "red" }}>{warrantyInfo ? "Brak wymaganego przeglądu" : (client_launch_date && parseInt(client_launch_date.slice(0, 4)) < 2000) ? "Urządzenie nie uruchomione" : "WYGASŁA"}</span></Typography></Grid> : null}
            {(!warrantyCheck(warrantyDate) || addWarranty === true) && !warrantyInfo ?
                <React.Fragment>
                    <Grid item xs={4}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                            <KeyboardDatePicker
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">od</InputAdornment>,
                                }}
                                variant="inline"
                                ampm={false}
                                placeholder="YYYY-MM-DD"
                                value={clientLaunchDate}
                                onChange={(e) => dateCheck(new Date(e), "clientLaunchDate")}
                                format="yyyy-MM-dd"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={4}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                            <KeyboardDatePicker
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">do</InputAdornment>,
                                }}
                                variant="inline"
                                ampm={false}
                                placeholder="YYYY-MM-DD"
                                value={warrantyDate}
                                onChange={(e) => dateCheck(new Date(e), "warrantyDate")}
                                format="yyyy-MM-dd"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>


                    <Grid item xs={2} className='no-padding'><Button onClick={() => handleChangeWarrantyDate()}
                        variant="contained" color="primary" style={{ float: "right" }}>Zapisz</Button></Grid>
                </React.Fragment> : <Grid item xs={4} className='no-padding'>{warrantyInfo ? null : <Button onClick={() => handleAddWarranty()}
                    variant="contained" color="primary" style={{ float: "right" }}>Przedłuż gwarancję</Button>}</Grid>}
            {partner && partner.name === "Rynek wtórny"? <Grid item xs={12} className='no-padding'>
                <Typography variant='h3' style={{ color: "red", marginTop: "15px" }}>Instalacja z rynku wtórnego o podstawowej gwarancji, jeżeli nie wykupiono innej. Gwarancja obowiązuje wyłącznie jeśli montaż został dokonany przez autoryzowanego przez Euros Energy instalatora.<br /><br /></Typography>
            </Grid> : null}
        </Grid>
    )
}

export default Warranty